import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Typography, Avatar, Box, useTheme, Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    quote: "The jewelry pieces are absolutely stunning, perfect for any occasion. The quality is top-notch!",
    name: "Ayesha Khan",
    rating: 5,
    avatar: "path_to_avatar1.jpg"
  },
  {
    quote: "I love how these bags effortlessly elevate my style. They are both elegant and functional.",
    name: "Sara Ali",
    rating: 5,
    avatar: "path_to_avatar2.jpg"
  },
  {
    quote: "These jewelry designs are a true reflection of timeless elegance. I feel so confident wearing them!",
    name: "Zainab Ahmed",
    rating: 5,
    avatar: "path_to_avatar3.jpg"
  },
  {
    quote: "The bags are not only stylish but also incredibly durable. I carry them everywhere!",
    name: "Fatima Malik",
    rating: 5,
    avatar: "path_to_avatar4.jpg"
  }
];

function TestimonialSlider() {
  const theme = useTheme();
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 960) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(4);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    adaptiveHeight: true,
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 4 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontSize: "45px", textAlign: 'center', fontWeight: "400", mb: 8, mt: 6 ,color:"#4b3539"}}>
        From Our Customers
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <Box key={index}>
                <Box sx={{
                  width: '100%',
                  maxWidth: { xs: '80%', sm: "80%", md: '70%', lg: "70%" },
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  border: '2px solid #dcdcdc',
                  borderRadius: '15px',
                  backgroundColor: '#fafafa',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  m: 'auto',
                  mb: 2,
                  textAlign: 'center',
                }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                    {Array.from({ length: testimonial.rating }, (_, i) => (
                      <StarIcon key={i} sx={{ color: '#ffc107' }} />
                    ))}
                  </Box>
                  <Typography variant="body1" sx={{ fontStyle: 'italic', mb: 2, minHeight: "100px" }}>
                    "{testimonial.quote}"
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                    <Avatar src={testimonial.avatar} sx={{ width: 60, height: 60, mr: 2 }} />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{testimonial.name}</Typography>
                      <Typography variant="caption" color="textSecondary">{testimonial.title}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TestimonialSlider;
