import axios from "../axiosConfig";

class Routes {
  // get all services
  getcontact = () => {
    let data = axios.get("/contact/");
    return data;
  };
  // /add-products/detail
  addcontact = (data) => {
    let response = axios.post("/contact/create", data);
    return response;
  };
  // update service by id
  getcontactById = (id) => {
    let response = axios.post(`/api/contact/update/${id}`);
    return response;
  };
  deleteContact = (id) => {
    let response = axios.delete(`/contact/delete-contact/${id}`);
    return response;
  };
}

export default new Routes();
