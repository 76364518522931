import axios from "../axiosConfig";

class Routes {
  // get all services
  getshipping = () => {
    let data = axios.get("/shipping/get");
    return data;
  };
  // /add-products/detail
  addshipping = (data) => {
    let response = axios.post("/shipping/create", data);
    return response;
  };
  // update service by id
  getshippingById = (id) => {
    let response = axios.post(`/shipping/update/${id}`);
    return response;
  };
  deleteShipping = (id) => {
    let response = axios.delete(`/shipping/delete-shipping/${id}`);
    return response;
  };
}

export default new Routes();
