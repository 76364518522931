import React from 'react';

import { Grid, Typography, useMediaQuery, useTheme,Box,Container } from '@mui/material';

import ProductPage from '../../components/Products';
import NavBar from '../../components/Products/navBar';
import Footer from '../../components/LandingPage/Footer';


const ProductsPage = () => {
  
 

  return (
   
      <>
      <NavBar />
      <Box sx={{ backgroundColor: 'white', minHeight: '100vh',mt:-6 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
            <ProductPage/>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
   
  );
}

export default ProductsPage;
