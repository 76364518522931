import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BannerApi, ReviewApi } from "../../../apis";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

function Services() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);

  useEffect(() => {
    ReviewApi.getreviews().then((res) => {
      setData(res.data.reviews);
    });
  }, []);

  const handleDelete = (id) => {
    ReviewApi.deleteReviews(id)
      .then((response) => {
        console.log(`Review with ID ${id} deleted successfully.`, response);
        // Remove the deleted coupon from the state immediately
        setData((prevData) => prevData.filter((review) => review.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting Review:", error);
      });
  };
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              View Reviews
            </Typography>
          </Box>
  
        </Box>
        <Grid container spacing={2} pt={5}>
          <Grid xs={12}>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ paddingRight: "20px" }}>Reviews ID</th>
                  <th style={{ paddingLeft: "40px" }}>Product ID</th>
                  {/* <th style={{ paddingLeft: "40px" }}>Name</th> */}
                  <th style={{ paddingLeft: "40px" }}>Comment</th>
                  <th style={{ paddingLeft: "40px" }}>Rating</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
  {data.length > 0 ? (
    data.map((service, index) => (
      <tr key={index} >
        <td style={{ paddingLeft: "40px" }}>{service.id}</td>
        <td style={{ paddingLeft: "40px" }}>{service.productId}</td>
        {/* <td style={{ paddingLeft: "40px" }}>{service.title}</td> */}
        <td style={{ paddingLeft: "40px" }}>{service.comment}</td>
        <td style={{ paddingLeft: "40px" }}>{service.rating}</td>
        <td>
          <ButtonGroup variant="text" aria-label="large button group" sx={{ paddingLeft: "auto" }}>
            {/* <Button style={{ color: 'grey' }} onClick={(event) => { event.stopPropagation(); navigate(`/edit-banner/${service.id}`); }}>
              <BorderColorIcon />
            </Button> */}
            <Button onClick={() => handleDelete(service.id)}>
                          <DeleteIcon color="error" />
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="10"  style={{ textAlign: "center" }}>No Reviews available</td>
    </tr>
  )}
</tbody>


            </table>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Services;
