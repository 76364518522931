import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { ShippingApi } from "../../../apis"; // Ensure correct import path

function Services() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
// Helper function to safely parse data only if it's a string
const safeParse = (data) => {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error("Error parsing data:", error);
      return data; // Return the original string if parsing fails
    }
  } else if (typeof data === 'object' && data !== null) {
    // It's already an object, so no need to parse
    return data;
  }
  return data; // Return as-is if it's not string or object
};


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await ShippingApi.getshipping();
      console.log("Fetched data:", response.data.shipping);

      // Safely parse each shipping item if necessary
      const parsedShipping = response.data.shipping.map(item => ({
        ...item,
        shippingInfo: safeParse(item.shippingInfo),
        cartItems: safeParse(item.cartItems),
      }));

      setData(parsedShipping);
    } catch (error) {
      setError('Failed to fetch shipping data.');
      console.error("Error fetching shipping data:", error);
    }
  };

  fetchData();
}, []);

  const handleDelete = async (id) => {
    try {
      await ShippingApi.deleteShipping(id); // Ensure this method is defined in your API
      setData(data.filter(item => item.id !== id)); // Remove deleted item from list
      console.log(`Shipping with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting shipping:", error);
      setError('Failed to delete shipping item.');
    }
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "20px",
          }}
        >
          Shipping List
        </Typography>
      </Box>
      {error && (
        <Typography color="error" align="center" sx={{ marginTop: '20px' }}>
          {error}
        </Typography>
      )}
      <TableContainer 
        component={Paper} 
        sx={{ 
          marginTop: 3,
          maxHeight: 500, // Set your fixed height here
          overflowY: 'auto', // Enables vertical scrolling
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ position: 'sticky', top: 0, backgroundColor: '#f2f2f2', zIndex: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", }}>Shipping ID</TableCell>
                  <TableCell style={{ fontWeight: "bold", paddingRight: "130px"  }}>Shipping Information</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Cart Items</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>

                </TableRow>
              </TableHead>
            </Table>
          </Box>
          <Box sx={{ maxHeight: 'calc(100% - 56px)', overflowY: 'auto' }}>
            <Table>
              <TableBody>
                {data.length > 0 ? (
                  data.map((shipping) => {
                    const shippingInfo = safeParse(shipping.shippingInfo || '{}');
                    const cartItems = safeParse(shipping.cartItems || '[]');

                    return (
                      <TableRow key={shipping.id}>
                        <TableCell>{shipping.id}</TableCell>

                        {/* Shipping Information */}
                        <TableCell style={{ paddingLeft: "200px" }}>
  <div>
    <strong>Full Name:</strong> {shippingInfo.fullName || 'N/A'}<br />
    <strong>Email:</strong> {shippingInfo.email || 'N/A'}<br />
    <strong>Address:</strong> {shippingInfo.address || 'N/A'}<br />
    <strong>City:</strong> {shippingInfo.city || 'N/A'}<br />
    <strong>Postal Code:</strong> {shippingInfo.postalCode || 'N/A'}<br />
    <strong>Phone:</strong> {shippingInfo.phone || 'N/A'}<br />
    <strong>Payment Method:</strong> {shippingInfo.paymentMethod || 'N/A'}<br />
    <strong>Total Price:</strong> {shipping.totalPrice || 'N/A'}<br />
    <strong>Delivery Fee:</strong> {shipping.deliveryFee || 'N/A'}<br />
    <strong>Grand Total:</strong> {shipping.grandTotal || 'N/A'}<br />
    <strong>Created At:</strong> {new Date(shipping.createdAt).toLocaleString() || 'N/A'}<br />
  </div>
</TableCell>


                        {/* Cart Items */}
                        <TableCell>
                          <ul>
                            {cartItems.map(item => (
                              <li key={item.id}>
                                <strong>Title:</strong> {item.title}<br />
                                <strong>Price:</strong> {item.price}<br />
                                <strong>Quantity:</strong> {item.quantity}<br />
                                <strong>Description:</strong> {item.description}<br />
                                <strong>Type:</strong> {item.type}<br />
                                <strong>Sub Type:</strong> {item.sub_type}<br />
                                <strong>Selected Color:</strong> {item.selectedColor}<br />
                                <strong>Image:</strong><br />
                                <img
                                  src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${item.imageToShow}`}
                                  alt={item.title}
                                  style={{ width: "150px", height: "100px", objectFit: "cover" }}
                                />
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                        <TableCell>  <ButtonGroup variant="text" aria-label="large button group" sx={{ paddingLeft: "auto" }}>
            {/* <Button style={{ color: 'grey' }} onClick={(event) => { event.stopPropagation(); navigate(`/edit-banner/${service.id}`); }}>
              <BorderColorIcon />
            </Button> */}
            <Button onClick={() => handleDelete(shipping.id)}>
                          <DeleteIcon color="error" />
            </Button>
          </ButtonGroup></TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={14} style={{ textAlign: "center" }}>No Shipping available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </TableContainer>
    </Box>
  );
}

export default Services;
