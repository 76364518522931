import axios from "../axiosConfig";

class Routes {
  // get all services
  getreviews = () => {
    let data = axios.get("/reviews/get");
    return data;
  };
  // /add-products/detail
  addreviews = (data) => {
    let response = axios.post("/reviews/create", data);
    return response;
  };
  // update service by id
  getreviewsById = (id) => {
    let response = axios.get(`/reviews/get/${id}`);
    return response;
  };
  deleteReviews = (id) => {
    let response = axios.delete(`/reviews/delete-reviews/${id}`);
    return response;
  };
}

export default new Routes();
