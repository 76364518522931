import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Card, IconButton, CardContent, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NavBar from './navBar';
import { useNavigate, useParams } from "react-router-dom";
import { ProductsApi, ReviewApi } from '../../apis';
import Reviews from './Reviews';
import Footer from '../LandingPage/Footer';
import { useCart } from '../../Contexts/productContext'; // Adjust the path as needed
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    marginTop: "150px"
  },
  card: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: '12px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    backgroundColor: '#fff',
    height: "550px",
    [theme.breakpoints.down('md')]: {
      height: 'auto', // Ensure height adjusts for small screens
    },
  },
  image: {
    objectFit: 'cover',
    width: '450px',
    height: '450px',
    borderRadius: '12px',
    border: '1px solid #ddd',
    marginLeft: 10,
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '380px', // Maintain aspect ratio
    },

  },

  content: {
    flex: 1,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 30
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: '#333',
    marginBottom: theme.spacing(1),
  },
  price: {
    fontSize: '1.5rem',
    color: '#f57c00',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: '1rem',
    color: '#555',
    marginBottom: theme.spacing(2),
    lineHeight: 1.6,
  },
  reviewsTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
    color: '#333',
  },
  reviewBox: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  commentSection: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    borderTop: '1px solid #ddd',
  },
  commentInput: {
    marginBottom: theme.spacing(2),
  },
  colorSelector: {
    marginTop: theme.spacing(4),
  },
  colorRadio: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: "primary.main",
    color: '#fff',
    '&:hover': {
      backgroundColor: "primary.main",
    },
    [theme.breakpoints.down('md')]: {
      width: '100%', // Make the button full-width on small screens
    },
  },
  errorMessage: {
    color: 'red',
    marginTop: theme.spacing(2),
  },
  productCard: {
    width: "240px",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "10px",
  },
  productImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    borderRadius: "12px",
  },
  productTitle: {
    fontWeight: "bold",
    marginTop: "10px",
    fontSize: "1.2rem",
    textAlign: "center",
  },
  productPrice: {
    fontSize: "1.1rem",
    color: "#f57c00",
    textAlign: "center",
    marginTop: "5px",
  },
  relatedProductsSection: {
    marginTop: "40px",
    padding: "20px 0",
    textAlign: "center",
  },
}));

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedColor, setSelectedColor] = useState('');
  const [product, setProduct] = useState(null);
  const [allProduct, setAllProduct] = useState([]);
  const [reviews, setReviews] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isQuantityExceeded, setIsQuantityExceeded] = useState(false);


  // State to manage the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  function multiParse(input) {
    let result = input;
    try {
      while (typeof result === 'string') {
        result = JSON.parse(result);
      }
    } catch (e) {
      console.error('Error parsing input:', e);
    }
    return result;
  }
  useEffect(() => {
    ProductsApi.getProductsById(id)
      .then((res) => {
        const service = res.data.product;
        let images = [];
        let imageColors = [];

        // Parse images
        if (service.images) {
          try {
            images = Array.isArray(service.images) ? service.images : [service.images];
          } catch (error) {
            images = [service.images];
          }
        }

        // Parse imageColors
        if (service.imageColors) {
          try {
            imageColors = Array.isArray(service.imageColors) ? service.imageColors : [service.imageColors];
          } catch (error) {
            imageColors = [];
          }
        }

        // Set initial color to the first color if available
        setProduct({ ...service, images, imageColors });
        if (imageColors.length > 0) {
          setSelectedColor(imageColors[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, [id]);

  useEffect(() => {
    ReviewApi.getreviewsById(id)
      .then((res) => {
        console.log("Fetched reviews:", res.data);
        setReviews(res.data.reviews);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  }, [id]);

  useEffect(() => {
    ProductsApi.getProducts()
      .then((res) => {
        const products = res.data.products.map((product) => {
          // Ensure images is always an array
          if (typeof product.images === 'string') {
            product.images = [product.images];
          }
          return product;
        });
        setAllProduct(products);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  // Select random 4 products from allProducts
  const getRandomProducts = () => {
    const shuffled = [...allProduct].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 4); // Return first 4 products
  };

  // Get random products to display
  const randomProducts = getRandomProducts();
  // Handle color change via radio button
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
    const newColorIndex = product.imageColors.indexOf(newColor);

    // Set the image index to the first image of the selected color
    setCurrentImageIndex(newColorIndex);
  };

  const handleQuantityChange = (action) => {
    // Update quantity based on action ('increase' or 'decrease')
    let newQuantity = quantity;

    if (action === 'increase' && quantity < product.quantity) {
      newQuantity = quantity + 1;
    } else if (action === 'decrease' && quantity > 0) {
      newQuantity = quantity - 1;
    }

    // Update quantity first
    setQuantity(newQuantity);

    // Check if the selected quantity exceeds the available quantity
    if (newQuantity >= product.quantity) {
      setIsQuantityExceeded(true);
    } else {
      setIsQuantityExceeded(false);
    }
  };



  const { addToCart } = useCart();
  const handleAddToCart = () => {
    if (!product) {
      setErrorMessage('Product not found.');
      return;
    }

    const colorIndex = product.imageColors.indexOf(selectedColor);
    const imageToShow = product.images[colorIndex] || product.images[0];

    // Prepare the product object to add to the cart
    const productToAdd = {
      ...product,
      quantity: Number(quantity), // Ensure quantity is a number
      selectedColor,
      imageToShow,
      max_quantity: Number(product.quantity), // Ensure max_quantity is a number
    };

    // Call addToCart function
    addToCart(productToAdd);

    // Log the product being added to the cart
    console.log("Adding to cart:", productToAdd);

    // Navigate to checkout
    navigate("/checkout");
  };


  // Handle next image
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
  };

  // Handle previous image
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  const imageToShow = product.images[currentImageIndex];  // Get image based on current index

  return (
    <>
      <Container className={classes.root}>
        <NavBar />
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ ml: -1, mt: -8 }} />
        </IconButton>
        <Card className={classes.card} sx={{ display: { md: 'flex' } }}>
          <Box className={classes.image} sx={{ position: 'relative' }}>
            {/* Conditionally render the arrows if there are more than one image */}
            {product.images.length > 1 && (
              <IconButton
                onClick={handlePrevImage}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  zIndex: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}

            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${imageToShow}`}
              alt={product.title}
              style={{ width: "100%", height: "450px", objectFit: "cover" }}
            />

            {product.images.length > 1 && (
              <IconButton
                onClick={handleNextImage}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: { xs: '20px', md: '10px' },
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  zIndex: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </Box>


          <CardContent className={classes.content}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: { md: '150px' },
                padding: 2,

              }}
            >
              <Typography component="span" sx={{ fontSize: "30px", mt: { xs: 1, md: 0 }, mb: 1 }} className={classes.title}>
                {product.title}
              </Typography>
              <div>
                {product.discounted_price ? (
                  <>
                    {product.show_price === "active" || product.show_price === "Active" && (
                      <Typography
                        variant="subtitle1"
                        color="black"
                        style={{ textDecoration: 'line-through', marginRight: '8px', color: "grey" }}
                      >
                        PKR {product.price}
                      </Typography>
                    )}
                    <Typography variant="subtitle1" color="#f57c00" mt={-1}>
                      PKR {product.discounted_price}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="subtitle1" color="#f57c00">
                    PKR {product.price}
                  </Typography>
                )}
              </div>



              <FormControl component="fieldset" className={classes.colorSelector} sx={{ mt: 2 }}>
                <RadioGroup
                  aria-label="color"
                  name="color"
                  value={selectedColor}
                  onChange={handleColorChange}
                  row
                >
                  {product.imageColors && product.imageColors.length > 0 ? (
                    <FormControl component="fieldset" className={classes.colorSelector} sx={{ mt: 2 }}>
                      <RadioGroup
                        aria-label="color"
                        name="color"
                        value={selectedColor}
                        onChange={handleColorChange}
                        row
                      >
                        {product.imageColors.map((color) => (
                          <FormControlLabel
                            key={color}
                            value={color}
                            control={<Radio sx={{ color: '#834F37', '&.Mui-checked': { color: '#834F37' } }} />}
                            label={color.charAt(0).toUpperCase() + color.slice(1)}
                            className={classes.colorRadio}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <Typography></Typography>
                  )}

                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography
                component="span"
                sx={{ fontSize: "15px", fontWeight: "bold" }}
                className={classes.title}
              >
                Select Quantity
              </Typography>

              {/* Quantity Selector */}
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => handleQuantityChange('decrease')}
                  sx={{
                    minWidth: '32px',
                    minHeight: '32px',
                    fontSize: '20px',
                    color: '#834F37'
                  }}
                  disabled={quantity <= 0}
                >
                  -
                </Button>

                <Typography
                  sx={{ mx: 2, fontSize: '16px', fontWeight: 'bold', color: '#834F37' }}
                  className={classes.quantity}
                >
                  {quantity}
                </Typography>

                <Button
                  variant="outlined"
                  onClick={() => handleQuantityChange('increase')}
                  sx={{
                    minWidth: '32px',
                    minHeight: '32px',
                    fontSize: '20px',
                    color: '#834F37'
                  }}
                  disabled={isQuantityExceeded || quantity >= product.quantity}
                >
                  +
                </Button>
              </Box>

              {/* Error Message if Quantity Exceeds Available Stock */}
              {isQuantityExceeded && (
                <Typography className={classes.errorMessage}>
                  {parseInt(product.quantity, 10) === 0
                    ? "Out of stock! No items available."
                    : `Out of stock! Only ${parseInt(product.quantity, 10)} items available.`
                  }
                </Typography>
              )}


              {/* Add to Cart Button */}
              <Button
  type="submit"
  variant="contained"
  fullWidth
  disabled={quantity > product.quantity + 1 || quantity <= 0} // Disable if quantity is greater than available stock + 1 or <= 0
  sx={{
    backgroundColor: quantity > product.quantity + 1 || quantity <= 0 ? 'grey' : '#834F37', // Set background color based on the new condition
    color: 'white',
    '&:hover': {
      backgroundColor: quantity > product.quantity + 1 || quantity <= 0 ? 'grey' : '#6c4d3f', // Hover color based on the same condition
    },
    mt: 2.5,
  }}
  onClick={handleAddToCart}
>
  Add to Cart
</Button>

            </Box>
            <Typography sx={{ mt: 2, ml: 2 }} className={classes.description}>
              {product.description}
            </Typography>
            {errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
          </CardContent>

        </Card>

        <Box className={classes.reviewBox} sx={{ mt: 4 }}>

          <Reviews initialReviews={reviews} productId={product.id} />
        </Box>
        {/* Related Products */}
        <Box className={classes.relatedProductsSection}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Related Products
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
            {randomProducts.map((relatedProduct) => (
              <Card key={relatedProduct.id} className={classes.productCard} onClick={() => {
                console.log("Navigating to:", relatedProduct.id);


                navigate(`/product/${relatedProduct.id}`);
                // Scroll to the top of the page
                window.scrollTo(0, 0);
              }}>                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${relatedProduct.images[0]}`}
                  alt={relatedProduct.title}
                  className={classes.productImage}
                />
                <Typography className={classes.productTitle}>{relatedProduct.title}</Typography>
                <Typography className={classes.productPrice}>PKR {relatedProduct.price}</Typography>
                {/* <Button
                  variant="contained"
                  onClick={() => navigate(`/product/${relatedProduct.id}`)}
                  sx={{ backgroundColor: '#834F37', color: 'white' }}
                >
                  View Product
                </Button> */}
              </Card>
            ))}
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default ProductDetailPage;
