import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ProductsApi, UploadImageApi } from "../../../apis";
import { makeStyles } from "@mui/styles";
import imageCompression from 'browser-image-compression'; // Import the compression library
const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
  },
});


const typeOptions = ["Jewelry", "Bags"];
const subTypeOptions = ["Earrings", "Rings", "Pendants", "Anklets", "Matha Pati", "Bindiya", "Bangles", "Bracelets"];


const ColorSelector = ({ selectedColor, onColorChange }) => {
  return (
    <div>
      <TextField

        value={selectedColor}
        onChange={onColorChange}
        variant="outlined"
        fullWidth
        placeholder="Type color name"
      />
      {selectedColor && (
        <div>
          <p>
            You entered: <strong>{selectedColor}</strong>
          </p>
          <div
            style={{
              width: "100px",
              height: "100px",
              backgroundColor: selectedColor,
              marginTop: "10px",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

const AddProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageColors, setImageColors] = useState([]);
  const [addOns, setAddOns] = useState([{ key: "", value: "" }]);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      id: id,
      type: "",
      sub_type: "",
      top_featured: "Active",
      price: "",
      discounted_price: "",
      quantity: "",
      title: "",
      description: "",
      addOns: addOns,
      images: [],
      imageColors: [],
    },
    onSubmit: async (values) => {
      try {
        console.log("Form Values:", values);

        let formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("price", values.price);
        formData.append("discounted_price", values.price);
        formData.append("quantity", values.quantity);
        formData.append("type", values.type);
        formData.append("sub_type", values.sub_type);
        formData.append("top_featured", values.top_featured);
        formData.append("addOns", JSON.stringify(values.addOns));

        const imageColorsMap = await handleImagesUpload();
        Object.keys(imageColorsMap).forEach((name) => {
          formData.append("images", name);
          formData.append("imageColors", imageColorsMap[name]);
        });

        await ProductsApi.addProducts(formData);
        navigate("/v1/products/detail");
      } catch (error) {
        console.error("Error occurred during form submission:", error);
      }
    },
  });
  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 10, // Maximum file size in MB
      maxWidthOrHeight: 800, // Maximum width or height of the image
      useWebWorker: true, // Enable web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);
    const compressedFiles = await Promise.all(
      files.map((file) => compressImage(file))
    );

    setImages((prevImages) => [...prevImages, ...compressedFiles]);
    setImagePreviews((prevPreviews) => [
      ...prevPreviews,
      ...compressedFiles.map((file) => URL.createObjectURL(file)),
    ]);
    setImageColors((prevColors) => [
      ...prevColors,
      ...Array(files.length).fill(""),
    ]);
  };

  const handleImagesUpload = async () => {
    if (images.length > 0) {
      try {
        const uploadPromises = images.map((file) => {
          const formData = new FormData();
          formData.append("file", file);
          return UploadImageApi.uploadImage(formData);
        });

        const responses = await Promise.all(uploadPromises);
        console.log("Upload responses:", responses);

        const imageNames = responses.map((res) => res.data.data.name);
        const imageColorsMap = imageNames.reduce((acc, name, index) => {
          acc[name] = imageColors[index] || "";
          return acc;
        }, {});

        return imageColorsMap;
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    }
    return {};
  };

  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);

    const updatedColors = [...imageColors];
    updatedColors.splice(index, 1);
    setImageColors(updatedColors);
  };

  const handleAddOnChange = (index, field, value) => {
    const updatedAddOns = [...addOns];
    updatedAddOns[index][field] = value;
    setAddOns(updatedAddOns);
    formik.setFieldValue("addOns", updatedAddOns);
  };

  const handleAddOnAdd = () => {
    setAddOns([...addOns, { key: "", value: "" }]);
  };

  const handleAddOnRemove = (index) => {
    const updatedAddOns = addOns.filter((_, i) => i !== index);
    setAddOns(updatedAddOns);
    formik.setFieldValue("addOns", updatedAddOns);
  };

  const handleColorChange = (index, event) => {
    const updatedColors = [...imageColors];
    updatedColors[index] = event.target.value;
    setImageColors(updatedColors);
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => navigate("/v1/products/detail")} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              Add Product Details
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "secondary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                "&:hover": {
                  background: "secondary.main",
                  outline: "none",
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Type:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  id="type"
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  {typeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {formik.values.type === "Jewelry" && (
              <Grid item xs={12}>
                <InputLabel mt={2} className={classes.inputLabels}>
                  Sub Type:
                </InputLabel>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    fullWidth
                    id="sub_type"
                    name="sub_type"
                    value={formik.values.sub_type}
                    onChange={formik.handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Sub Type
                    </MenuItem>
                    {subTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
              Best Sellers:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  id="top_featured"
                  name="top_featured"
                  value={formik.values.top_featured}
                  onChange={formik.handleChange}
                  displayEmpty
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Price:
              </InputLabel>
              <TextField
                fullWidth
                id="price"
                name="price"
                type="number"
                value={formik.values.price}
                onChange={formik.handleChange}
                placeholder="Enter price"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Quantity:
              </InputLabel>
              <TextField
                fullWidth
                id="quantity"
                name="quantity"
                type="number"
                value={formik.values.quantity}
                onChange={formik.handleChange}
                placeholder="Enter quantity"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Title:
              </InputLabel>
              <TextField
                fullWidth
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder="Enter title"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Description:
              </InputLabel>
              <TextField
                fullWidth
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Enter description"
                variant="outlined"
                multiline
                rows={4}
              />
            </Grid>

            {/* {addOns.length > 0 ? (
              addOns.map((addOn, index) => (
                <Grid item xs={12} key={index}>
                  <Box display="flex" alignItems="center">
                    <TextField
                      fullWidth
                      id={`addOns[${index}].key`}
                      name={`addOns[${index}].key`}
                      value={addOn.key}
                      onChange={(e) => handleAddOnChange(index, "key", e.target.value)}
                      placeholder="Add-on name"
                      variant="outlined"
                    />
                    <TextField
                      fullWidth
                      id={`addOns[${index}].value`}
                      name={`addOns[${index}].value`}
                      value={addOn.value}
                      onChange={(e) => handleAddOnChange(index, "value", e.target.value)}
                      placeholder="value"
                      type="number"
                      variant="outlined"
                      sx={{ ml: 2 }}
                    />
                    <IconButton
                      onClick={() => handleAddOnRemove(index)}
                      sx={{ ml: 2 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography>No add-ons available</Typography>
              </Grid>
            )} */}

            {/* <Grid item xs={12}>
              <Button
                variant="standard"
                onClick={handleAddOnAdd}
               sx={{backgroundColor:"primary.main"}}
              >
                Add More Add-ons
              </Button>
            </Grid> */}


            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Images:
              </InputLabel>
              <input
                type="file"
                multiple
                onChange={handleImageChange}
              />
              <Box mt={2} display="flex" flexWrap="wrap">
                {imagePreviews.map((preview, index) => (
                  <Box key={index} sx={{ position: "relative", margin: 1 }}>
                    <img
                      src={preview}
                      alt={`Preview ${index}`}
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                    <IconButton
                      onClick={() => handleImageDelete(index)}
                      sx={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <ColorSelector
                      selectedColor={imageColors[index]}
                      onColorChange={(e) => handleColorChange(index, e)}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default AddProductDetails;
