import React, { useState } from 'react';
import { Typography, Grid, Card, CardContent, Button, TextField, Divider, RadioGroup, FormControlLabel, Radio, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCart } from '../../Contexts/productContext'; // Adjust the path as needed
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ProductsApi, ShippingApi } from '../../apis';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { styled } from '@mui/system';


const validationSchema = yup.object({
  fullName: yup.string().required('Full name is required'),
  email: yup.string().required('Email is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  phone: yup
  .string()
  .matches(/^[0-9]+$/, 'Phone number must only contain numbers')
  .min(11, 'Phone number must be 11 digits') // Ensure the phone number has at least 11 digits
  .max(11, 'Phone number must not exceed 11 digits') // Ensure the phone number does not exceed 11 digits
  .required('Phone number is required'),
  postalCode: yup
  .string()
  .matches(/^[0-9]+$/, 'Postal Code must only contain numbers')
  .required('Postal Code is required'),
  paymentMethod: yup.string().required('Payment method is required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: 1200,
    margin: 'auto',
    marginTop: '150px',
  },
  title: {
    fontSize: '1.8rem',
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  cartItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: '1px solid #ddd',
  },
  productDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: 80,
    height: 80,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    borderRadius: '4px',
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  price: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  quantityControl: {
    display: 'flex',
    alignItems: 'center',
  },
  quantityButton: {
    minWidth: '36px',
    height: '36px',
    padding: '0',
  },
  total: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  checkoutButton: {
    marginTop: theme.spacing(3),
  },
  card: {
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));
const CheckoutPage = () => {
  const classes = useStyles();
  const {errorMessage, setErrorMessage, getOriginalTotalPrice,activeItemId, setActiveItemId,
    getDiscountedPrice,discount, setDiscount,coupon, setCoupon,setCartItems,cartItems, formData, setFormData, removeFromCart, getTotalPrice, getDeliveryFee, increaseQuantity, decreaseQuantity } = useCart();
  const navigate = useNavigate();
console.log("hbjv",getOriginalTotalPrice(),
  getDiscountedPrice());

  // State for popup
  const [openDialog, setOpenDialog] = useState(false);

  const CustomButton = styled(Button)({
    backgroundColor: '#834F37', // Customize your button color
    borderRadius: '12px', // Rounded corners
    color: '#fff',
    padding: '10px 20px',
    textTransform: 'none', // Keep text case as-is
    fontSize: '16px', // Customize font size
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Custom shadow
    marginLeft: '10px', // Spacing between the text field and button
    '&:hover': {
      backgroundColor: '#834F37', // Hover effect
    },
  });
  
  function CouponField() {
    // const [discount, setDiscount] = useState(null);
    const [error, setError] = useState(null); // To handle errors
  
    // // Validation schema for Formik (assuming you're using Yup)
    // const validationSchema = yup.object({
    //   coupon: yup.string().required('Coupon code is required'),
    // });
  
    // Function to apply the coupon
    const handleApplyCoupon = async (couponCode) => {
      try {
        // Make API call to fetch coupon details using the coupon code
        const response = await ProductsApi.getCouponByCode(couponCode);
  
        // Assuming response contains a coupon object with a discount property
        const discount = response.data.coupon.discount;
        if (discount) {
          setDiscount(discount); // Set the discount in state
          console.log('Coupon applied:', couponCode, 'Discount:', discount);
          setError(null); // Clear any previous errors
        } else {
          setError('Invalid coupon or no discount available.');
        }
      } catch (error) {
        setError('Invalid coupon or no discount available.');
        console.error('Error fetching coupon:', error);
      }
    };
  
    const formik = useFormik({
      initialValues: {
        coupon: '',
      },
      onSubmit: (values) => {
        handleApplyCoupon(values.coupon); // Pass coupon code to handleApplyCoupon
      },
    });
  
    return (
      <Box display="flex" flexDirection="column" alignItems="flex-start" ml="auto" mt={1}>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" alignItems="center" width="100%">
            <TextField
              name="coupon"
              placeholder="Enter Coupon Code"
              variant="outlined"
              value={formik.values.coupon}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              
              
            />
  
            <CustomButton type="submit" variant="contained">
              Apply
            </CustomButton>
          </Box>
        </form>
        {discount && <Box  ml="auto"><p>Discount: {discount}%</p></Box>}
    


        {/* Discount and error messages below the TextField and Button */}
       
        {error && <Box ml="auto"><p style={{ color: 'red' }}>{error}</p></Box>}
      </Box>
    );
  }
  
  // Handle form data change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email:'',
      address: '',
      city: '',
      postalCode: '',
      phone: '',
      paymentMethod: 'cod',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const totalPrice = getTotalPrice();
        const deliveryFee = getDeliveryFee();
        const grandTotal = totalPrice + deliveryFee;


        await ShippingApi.addshipping({
          shippingInfo: values,
          cartItems,
          totalPrice,
          deliveryFee,
          grandTotal,
        });
  // Store shipping information in formData
  setFormData(prevState => ({
    ...prevState,
    shippingInfo: {
      ...values,
      totalPrice,
      deliveryFee,
      grandTotal,
    },
  }));
  console.log("yfvitfvg",formData);
  
        setOpenDialog(true); // Show the popup with order detail
        formik.resetForm();
       
      } catch (error) {
        console.error('Error submitting shipping information:', error);
      }
    },
  });

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCartItems([]);
  };



  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {/* Cart Items */}
         {/* Cart Items */}
         <Grid item xs={12} md={8}>
         <IconButton onClick={() => navigate(-1)}>
      <ArrowBackIcon sx={{ml:-1,mt:-8}} />
    </IconButton>
          <Card className={classes.card}>
          <CardContent>
            
  {cartItems.length === 0 ? (
    <Typography>No items in the cart.</Typography>
  ) : (
    <>
    
      <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
        Checkout
      </Typography>
      {cartItems.map((item) => (
        <Grid container key={item.productId} className={classes.cartItem} spacing={1} alignItems="center">
          <Grid item xs={12} md={2}>
          <Box
      sx={{
        width: '100%',
        height: {
          xs: '200px', // Height for extra-small screens
          md: '100px', // Height for medium screens and above
        },
        objectFit: 'cover', // Ensures the image covers the area without distortion
        paddingRight: 2, // Adjust padding as needed
        overflow: 'hidden', // Ensures no overflow for the image
        mx:"auto"
      }}
    >
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${item.imageToShow}`}
              alt={item.title}
              style={{ width: "100%", height: "100%",objectFit:"cover"}}
            />
                </Box>
          </Grid>
          <Grid item xs={12} md={2} >
            <Typography>{item.title}</Typography>
            <Typography variant="body2">Color: {item.selectedColor}</Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.quantityControl}>
      <IconButton
        className={classes.quantityButton}
        onClick={() => decreaseQuantity(item.id)}
      >
        <RemoveIcon />
      </IconButton>
      <Typography className={classes.price}>
        {item.quantity}
      </Typography>
      <IconButton
        className={classes.quantityButton}
        onClick={() => increaseQuantity(item.id)}
        disabled={activeItemId === item.id && !!errorMessage}
      >
        <AddIcon />
      </IconButton>
      {errorMessage && activeItemId === item.id && (
        <Typography color="error" variant="caption" style={{ display: 'inline', marginLeft: '8px' }}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
          <Grid item xs={12} md={2} className={classes.price} sx={{ml:2}}>
            <Typography>
             PKR {item.discounted_price * item.quantity} 
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              sx={{
                backgroundColor: "#834F37",
                color: '#fff',
                '&:hover': {
                  backgroundColor: "#834F37",
                },
              }}
              onClick={() =>
               
                
                removeFromCart(item.id)}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      ))}



      <div style={{
        marginTop: '16px',
        padding: '16px',
        borderTop: '2px solid #ddd',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#333' }}>
              Total:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#834F37' }}>
             PKR {getTotalPrice()}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#333' }}>
              Delivery Fee:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#834F37' }}>
              PKR {getDeliveryFee()}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#333' }}>
              Grand Total:
            </Typography>
          </Grid>
          {!discount && (
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#834F37' }}>
            PKR {(parseFloat(getTotalPrice()) + getDeliveryFee()).toFixed(2)} 
            </Typography>
          </Grid>)}
          {discount && (
  <Box ml="auto" sx={{mt:2}}>
    <Typography 
      sx={{ 
        fontWeight: 'bold', 
        fontSize: '20px', 
        color: 'gray', 
        textDecoration: 'line-through', 
        display: 'inline', // Ensure both elements are on the same line
        marginRight: '8px' // Add some space between the prices
      }}
    >
      PKR {getOriginalTotalPrice()} {/* Original price with a line */}
    </Typography>

    <Typography 
      sx={{ 
        fontWeight: 'bold', 
        fontSize: '20px', 
        color: 'primary.main', 
        display: 'inline' // Keep discounted price in the same line
      }}
    >
       PKR {getDiscountedPrice()} {/* Discounted price */}
    </Typography>
  </Box>
)}
          <CouponField/>
        </Grid>
      
      </div>
    </>
  )}
</CardContent>

          </Card>
        </Grid>

          {/* Shipping Information */}
          <Grid item xs={12} md={4}>
          <Card className={classes.card}>
            <CardContent>
              
              <Typography variant="h6">Shipping Information</Typography>
              <form onSubmit={formik.handleSubmit}>
                <TextField sx={{mt:1}}
                  name="fullName"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Full Name"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  required
                  error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
                <TextField sx={{mt:1}}
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  required
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                  <TextField sx={{mt:1}}
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Phone"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  required
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
                <TextField
                  name="address"  sx={{mt:1}}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Address"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  required
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
                <TextField sx={{mt:1}}
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="City"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  required
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
                <TextField sx={{mt:1}}
                  name="postalCode"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Postal Code"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  required
                  error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                  helperText={formik.touched.postalCode && formik.errors.postalCode}
                />
              
               

                 <RadioGroup
                  name="paymentMethod" sx={{mt:1}}
                  value={formik.values.paymentMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Payment Method"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                >
                  <FormControlLabel
                    value="cod" 
                    control={<Radio sx={{ color: 'gray' }} />}
                    label="Cash on Delivery"
                  />
                </RadioGroup>
                <Typography sx={{px:1}}>For online payment please contact on this number. </Typography>
                <a href="https://wa.me/+923079566695" target="_blank" rel="noopener noreferrer">
                <Typography sx={{px:1}}>+92 307 9566695 </Typography>
        </a>
                <Button sx={{mt:3}}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.checkoutButton}
                  fullWidth
                >
                  Confirm Order
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>Order Confirmation</DialogTitle>
      <DialogContent>
      <Grid item xs={12} md={8}>
      <Typography
  variant="h6"
  component="h2"
  sx={{
    fontWeight: 'bold',
    color: 'rgb(131, 79, 55)', // Green color for a positive message
    textAlign: 'center',
    padding: 2,
    backgroundColor: '#834f3729', // Light green background for emphasis
    borderRadius: 1,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }}
>
  Thank You for placing the order! Your order is confirmed.
</Typography>

       
          <Card className={classes.card}>
          <CardContent>
  {cartItems.length === 0 ? (
    <Typography>No items in the cart.</Typography>
  ) : (
    <>
     
      <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
        Order details
      </Typography>
      {cartItems.map((item) => (
        <Grid container key={item.productId} className={classes.cartItem} spacing={1} alignItems="center">
          <Grid item xs={12} md={2}>
          <Box
      sx={{
        width: '100%',
        height: {
          xs: '200px', // Height for extra-small screens
          md: '100px', // Height for medium screens and above
        },
        objectFit: 'cover', // Ensures the image covers the area without distortion
        paddingRight: 2, // Adjust padding as needed
        overflow: 'hidden', // Ensures no overflow for the image
        mx:"auto"
      }}
    >
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${item.imageToShow}`}
              alt={item.title}
              style={{ width: "100%", height: "100%"}}
            />
                </Box>
          </Grid>
          <Grid item xs={12} md={2} >
            <Typography >{item.title}</Typography>
         
          </Grid>
          <Grid item xs={12} md={2} >
            
            <Typography sx={{ml:{md:2}}}variant="body2"><strong>Color: </strong>{item.selectedColor}</Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.quantityControl}>
          
            <Typography className={classes.price}>
            <strong>Quantity:</strong> {item.quantity}
            </Typography>
          
          </Grid>
          <Grid item xs={12} md={2} className={classes.price}>
            <Typography>
            <strong>Price: </strong>PKR {item.price * item.quantity}
            </Typography>
          </Grid>
         
        </Grid>
      ))}


      <div style={{
        marginTop: '16px',
        padding: '16px',
        borderTop: '2px solid #ddd',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#333' }}>
              Total:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#834F37' }}>
            PKR {getTotalPrice()} 
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#333' }}>
              Delivery Fee:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#834F37' }}>
            PKR {getDeliveryFee()} 
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#333' }}>
              Grand Total:
            </Typography>
          </Grid>
          {discount && <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px', color: '#834F37' }}>
            PKR {(parseFloat(getTotalPrice()) + getDeliveryFee()).toFixed(2)}
            </Typography>
          </Grid>}
        </Grid>
      </div>
    </>
  )}
</CardContent>

          </Card>
        </Grid>
        <div style={{
        marginTop: '16px',
        padding: '16px',
        borderTop: '2px solid #ddd',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>Shipping Information:</Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography><strong>Full Name:</strong> {formData?.shippingInfo?.fullName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography><strong>Email:</strong> {formData?.shippingInfo?.email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography><strong>Phone:</strong> {formData?.shippingInfo?.phone}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography><strong>Address:</strong> {formData?.shippingInfo?.address}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography><strong>City:</strong> {formData?.shippingInfo?.city}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography><strong>Payment Method:</strong> {formData?.shippingInfo?.paymentMethod}</Typography>
          </Grid>
        </Grid>
</div>
        <Divider sx={{ my: 3 }} />

       
      
      </DialogContent>
    
    </Dialog>

    </div>
  );
};

export default CheckoutPage;
