import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import { BannerApi } from '../../apis'; // Import your Banner API
import banner from "../../assets/images/banner.jpg"; // Local fallback image

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '600px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left',
    padding: '20px',
    '@media (max-width: 600px)': {
      padding: '10px',
    },
  },
  headline1: {
    marginBottom: '16px',
    paddingLeft: '8%',
    '@media (max-width: 600px)': {
      paddingLeft: '2%',
    },
  },
  buttonArea: {
    paddingLeft: '5.1%',
    marginTop: "40px",
    display: 'flex',
    gap: '16px',
    '@media (max-width: 600px)': {
      paddingLeft: '2%',
      marginTop: 110,
    },
    '@media (max-width: 1200px)': {
      paddingLeft: '8%',
    },
  },
});

function Banner() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [bannerImage, setBannerImage] = useState(''); // State to hold banner image URL

  useEffect(() => {
    // Fetch banner data
    BannerApi.getBanner()
      .then((res) => {
        const imageUrl = res.data.banners && res.data.banners.length > 0
          ? `${process.env.REACT_APP_API_BASE_URL}/public/image/${res.data.banners[0].image}`
          : null;

        // If the image is available, set it, otherwise use the local banner
        setBannerImage(imageUrl || banner);
      })
      .catch((error) => {
        console.error('Error fetching banner:', error);
        setBannerImage(banner); // Set fallback image in case of an error
      });
  }, []);

  return (
    <Box className={classes.root} style={{ backgroundImage: `url(${bannerImage})` }}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mt: { xs: 8, sm: 10 } }} className={classes.headline1}>
          SPICE UP YOUR STYLE WITH{' '}
          <span style={{ color: '#834F37' }}>SALEECA</span>
        </Typography>
      </motion.div>

      <Box className={classes.buttonArea}>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: '#834F37',
              color: 'white',
              '&:hover': {
                backgroundColor: '#6f3e29',
              },
              mt:6
            }}
            onClick={() => navigate("/products")}
          >
            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', padding: "5px" }}>
              Shop Now
            </Typography>
          </Button>
        </motion.div>

        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Button variant="outlined" onClick={() => navigate("/contact-us")} sx={{
            color: '#834F37',
            borderColor: '#834F37',
            fontWeight: 'bold',
            '&:hover': {
              borderColor: '#6f3e29',
            },
            mt: 6
          }}>
            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', padding: "5px" }}>
              Contact Us
            </Typography>
          </Button>
        </motion.div>
      </Box>
    </Box>
  );
}

export default Banner;
