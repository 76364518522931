import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { CartApi } from "../../../apis"; // Assuming CartApi is the API for cart operations
const multiParse = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error("Error parsing data:", error);
    return data; // Return original if parsing fails
  }
};
function Services() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    CartApi.getcart()
      .then((res) => {
        // Parse each cart item if necessary
        const parsedItems = res.data.cartItems.map(item => multiParse(item));
        setData(parsedItems);
      })
      .catch((error) => {
        console.error("Error fetching cart items:", error);
      });
  }, []);


  const handleDelete = (productId) => {
    // Implement delete functionality
    console.log(`Delete item with productId: ${productId}`);
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "20px",
          }}
        >
          View Cart
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product ID</TableCell>
                  <TableCell style={{ paddingLeft: "40px" }}>Title</TableCell>
                  <TableCell style={{ paddingLeft: "40px" }}>Price</TableCell>
                  <TableCell style={{ paddingLeft: "40px" }}>Quantity</TableCell>
                  <TableCell style={{ paddingLeft: "40px" }}>Image</TableCell>
                  {/* <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? (
                  data.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.productId}</TableCell>
                      <TableCell style={{ paddingLeft: "40px" }}>{item.title}</TableCell>
                      <TableCell style={{ paddingLeft: "40px" }}>{item.price}</TableCell>
                      <TableCell style={{ paddingLeft: "40px" }}>{item.quantity}</TableCell>
                      <TableCell >
                        {item.product.images && (
                          <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                            <img
                              src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${JSON.parse(item.product.images)[0]}`}
                              alt={item.title}
                              style={{ width: "150px", height: "100px", objectFit: "cover" }}
                            />
                          </Box>
                        )}
                      </TableCell>
                      {/* <TableCell>
                        <ButtonGroup variant="text" aria-label="large button group">
                          <Button
                            style={{ color: 'grey' }}
                            onClick={() => navigate(`/edit-banner/${item.productId}`)}
                          >
                            <BorderColorIcon />
                          </Button>
                          <Button onClick={() => handleDelete(item.productId)}>
                            <DeleteIcon color="error" />
                          </Button>
                        </ButtonGroup>
                      </TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      No products available in the cart
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Services;
