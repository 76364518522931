import React from 'react';
import { Box, Grid, Typography, Link, TextField, Button, IconButton, Divider } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/ContactPhoneSharp';
import Logo from "../../assets/images/saleeca2.png";

function Footer() {
  return (
    <Box sx={{ bgcolor: '#4b3539', color: 'white', py: 4, px: 10 }}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={4}>
        <Box sx={{ marginRight: "2%",mb: 0.5}}>
          <img src={Logo} alt="logo" style={{ width: "100px" }} />
        </Box>
          <Typography variant="body2" sx={{ color: "white" }}>
            Great platform for who are passionate
          </Typography>
          <Typography variant="body2" sx={{ color: "white" }}>
            about jewelery and bags
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold',mb:2 }}>Quick Links</Typography>
          <Link href="/" color="inherit" sx={{ display: 'block', mb: 1, color: "white", textDecoration: "none" }}>Home</Link>
          <Link href="/products" color="inherit" sx={{ display: 'block', mb: 1, color: "white", textDecoration: "none" }}>Shop</Link>
          <Link href="/contact-us" color="inherit" sx={{ display: 'block', mb: 1, color: "white", textDecoration: "none" }}>Contact</Link>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold',mb:2 }}>Get In Touch</Typography>
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
        <EmailIcon sx={{ mr: 1 }} /> {/* Margin right to space out the icon and text */}
        saleeca007@gmail.com
      </Typography>
      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
        <PhoneIcon sx={{ mr: 1 }} /> {/* Margin right to space out the icon and text */}
        +92 307 9566695
      </Typography>
      <Box sx={{ display: 'block', mt: 2, color: "white" }}>
      <div>
        <a href="https://wa.me/+923079566695" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white' }}>
            <WhatsAppIcon />
          </IconButton>
        </a>
        <a href="https://web.facebook.com/people/Saleeca/100087290356157/" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white' }}>
            <FacebookIcon />
          </IconButton>
        </a>
        <a href="https://www.instagram.com/saleecaofficial/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white' }}>
            <InstagramIcon />
          </IconButton>
        </a>
        <a href="https://www.linkedin.com/company/103219537/admin/dashboard/" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white' }}>
            <LinkedInIcon />
          </IconButton>
        </a>
      </div>
    </Box>

          {/* <Box sx={{ mt: 1, display: 'flex', alignItems: 'center',mt:2 }}>
            <TextField variant="outlined" placeholder="Email Address" sx={{ flex: 1, mr: 1, bgcolor: 'white', borderRadius: "10px", justifyContent: "center" }} />
            <Button variant="contained" sx={{
              bgcolor: '#e4a47c', color: '#834F37', textTransform: 'none', height: "55px", borderRadius: "10px", fontWeight: 'bold', '&:hover': {

                backgroundColor: '#e4a47c'
              }
            }}>Subscribe</Button>
          </Box> */}
        </Grid>
      </Grid>
      <Divider sx={{ mt: 5, borderColor: "#AB7B5D" }} />
      <Grid item xs={12} sm={12} md={6}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
      alignItems: 'center',
      justifyContent: { sm: 'space-between' }, // Apply space-between on larger screens
      mt: 2,
    }}
  >
    <Typography variant="caption" sx={{ color: "white", textAlign: { xs: 'center', sm: 'left' } }}>
      Copyright © Saleeca
    </Typography>
    
    <Typography variant="caption" sx={{ mt: { xs: 2, sm: 0 }, color: "white", textAlign: { xs: 'center', sm: 'right' } }}>
      Developed by
      <a 
        href="https://www.linkedin.com/in/syeda-aimon-zehra-14172627a/" 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ 
          color: '#D2B48C', // Light brown color
          textDecoration: 'underline', // Adds the underline
          marginLeft: '5px' 
        }}
      >
        Aimon Zehra
      </a>
    </Typography>
  </Box>
</Grid>

      
    </Box>
  );
}

export default Footer;
