import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import Login from '../pages/Auth/login';
import LandingPage from "../pages/Landing/index";
import ProductPage from "../../src/pages/ProductsPage/index";
import Main from '../pages/Admin/main';
import ProductDetailPage from "../components/Products/detailPage";
import CheckoutPage from "../pages/Checkoutpage";
import ContactUs from "../pages/ContactUs/index";
export default function AppRoute() {

  function isAuthenticated() {
    return localStorage.getItem('token') !== null;
    
  }
  return (
    <BrowserRouter>
      <Routes>
        
                  <>
 
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/products" element={<ProductPage />} />
                  <Route path="/product/:id" element={<ProductDetailPage />} />
                  <Route path="/checkout" element={<CheckoutPage />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/login" element={<Login />} />
                   
                 
                  </>
       
          {isAuthenticated() && (
            
          <Route path="/v1*" element={<Main />} />
          )}
        </Routes>
    </BrowserRouter>
 
  );
}
