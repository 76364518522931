import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import AppRoute from './router';
import { CartProvider } from './Contexts/productContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Slide, toast } from 'react-toastify';

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  rtl: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  progress: undefined,
  theme: "light",
  zIndex: 9999
};

function App() {

  return (
    <ThemeProvider theme={theme}>
        <ToastContainer transition={Slide} {...toastOptions} />
        <CartProvider>
        <AppRoute />
        </CartProvider>
 
    </ThemeProvider>
  );
}

export default App;
