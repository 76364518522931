import axios from "../axiosConfig";

class Routes {
  // get all services
  getProducts = () => {
    let data = axios.get("/products/get");
    return data;
  };
  // get service by id
  getProductsById = (id) => {
    let data = axios.get(`/products/get/${id}`);
    return data;
  };
  // /add-products/detail
  addProducts = (data) => {
    let response = axios.post("/products/create", data);
    return response;
  };
  // update service by id
  updateProducts = (id, data) => {
    let response = axios.post(`/products/update/${id}`, data);
    return response;
  };
  deleteProducts = (id) => {
    let response = axios.delete(`/products/delete/${id}`);
    return response;
  };
  addCoupon = (data) => {
    let response = axios.post("/products/coupon/create", data);
    return response;
  };
  // update service by id
  getCouponByCode = (couponCode) => {
    return axios.get(`/products/coupon/${couponCode}`);
  };
  
  getCoupon = () => {
    let response = axios.get(`/products/coupon`);
    return response;
  };
   // update service by id
   updateCoupon = (id, data) => {
    let response = axios.post(`/products/update-coupon/${id}`, data);
    return response;
  };
  deleteCoupon = (id) => {
    let response = axios.delete(`/products/delete-coupon/${id}`);
    return response;
  };
}

export default new Routes();
