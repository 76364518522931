import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, useMediaQuery, useTheme, Box } from '@mui/material';
import { motion } from 'framer-motion';
import CompanyImage1 from "../../assets/images/sl5.png";
import CompanyImage2 from "../../assets/images/sl11.png"; // Add more images as needed
import { useNavigate } from "react-router-dom";

function CompanySection() {
    const theme = useTheme();
    const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const images = [CompanyImage1, CompanyImage2]; // Add more images to the array if needed
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Cycle through images every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length]);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "#AB7B5D",
            height: matchesSmall ? "680px" : "430px" // Adjust height based on screen size
        }}>
            <Grid container>
                <Grid 
                    item xs={12} sm={6} md={6}
                    sx={{
                        bgcolor: '#AB7B5D',
                        color: 'white',
                        px: "10%"
                    }}
                    component={motion.div}
                    initial={{ opacity: 0, x: -100 }} // Start off-screen to the left
                    animate={{ opacity: 1, x: 0 }} // Fade in and slide to the final position
                    transition={{ duration: 1 }} // Animation duration
                >
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with text slightly below and invisible
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and correct position
                        transition={{ duration: 1, delay: 0.2 }} // Delay to create a staggered effect
                    >
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white' }}>
                            Dazzle in Style: Saleeca Jewelry 
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with text slightly below and invisible
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and correct position
                        transition={{ duration: 1, delay: 0.4 }} // Delay to create a staggered effect
                    >
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white', mb: 3 }}>
                            for Every Occasion
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with text slightly below and invisible
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and correct position
                        transition={{ duration: 1, delay: 0.6 }} // Delay to create a staggered effect
                    >
                        <Box sx={{ display: 'flex', mb: 2 }}>
                            <Typography variant="body1" sx={{ mb: 4, color: "#4b3539" }}>
                                Find the ideal jewelry pieces for your everyday style
                            </Typography>
                        </Box>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with button slightly below and invisible
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and correct position
                        transition={{ duration: 1, delay: 0.8 }} // Delay to create a staggered effect
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                                width: "200px", color: '#4b3539', borderColor: '#4b3539', fontWeight: 'bold', '&:hover': {
                                    borderColor: '#4b3539'
                                }
                            }}
                            onClick={() => navigate("/products")}
                            whileHover={{ scale: 1.1 }} // Scale up on hover
                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '16px', fontWeight: 'bold', textTransform: 'none', color: '#4b3539', padding: "5px" }}>
                                Shop
                            </Typography>
                        </Button>
                    </motion.div>
                </Grid>
                
                <Grid
                    item xs={12} sm={6} md={6}
                >
                    <Box
                        sx={{
                            width: "300px",
                            height: "300px",
                            border: "1px dotted transparent",
                            borderRadius: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "15px",
                            margin: "auto",
                            overflow: "hidden", // Ensure the image doesn't overflow the box
                        }}
                    >
                        <motion.img 
                            key={currentImageIndex} // Use key to trigger re-render on image change
                            src={images[currentImageIndex]} 
                            alt="Dream Job"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "30px",
                            }}
                            initial={{ opacity: 0 }} // Start with hidden
                            animate={{ opacity: 1 }} // Fade in
                            transition={{ duration: 1 }} // Animation duration
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
        
    );
}

export default CompanySection;
