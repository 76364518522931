import React, { useState,useEffect } from "react";
import { Avatar, Box, Button, Rating, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ReviewApi } from "../../apis";

const useStyles = makeStyles((theme) => ({
  commentSection: {
    marginTop: theme.spacing(4),
  },
  reviewsTitle: {
    marginBottom: theme.spacing(2),
  },
  reviewBox: {
    padding: theme.spacing(2),
    backgroundColor: "white",
    marginBottom: theme.spacing(2),
    borderRadius: "4px",
  },
  commentInput: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  rating: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    color: "red",
    marginTop: theme.spacing(1),
  },
}));

const Reviews = ({ productId, initialReviews }) => {
  const classes = useStyles();
  const [reviews, setReviews] = useState(Array.isArray(initialReviews) ? initialReviews : []);


  useEffect(() => {
    if (Array.isArray(initialReviews)) {
      setReviews(initialReviews);
    } else {
      ReviewApi.getreviewsById(productId)
        .then((res) => {
          console.log("Fetched Rs:", res.data.reviews);
          setReviews(res.data.reviews || []); // Ensure it's an array
        })
        .catch((error) => {
          console.error("Error fetching reviews:", error);
        });
    }
  }, [productId, initialReviews]);
  

  // Validation schema for Formik
  const validationSchema = Yup.object({
    comment: Yup.string().required("Comment cannot be empty."),
    rating: Yup.number().required("Please provide a rating.").min(1, "Rating must be at least 1."),
  });
  
  const handleSubmit = async (values, { resetForm, setSubmitting, setErrors }) => {
    try {
      console.log("Form Values:", values);
  
      let formData = new FormData();
      formData.append("productId", productId);
      formData.append("comment", values.comment);
      formData.append("rating", values.rating);
  
      await ReviewApi.addreviews(formData);
  
      // Create a new review object
      const newReview = {
        comment: values.comment,
        rating: values.rating,
      };
  
      // Safely update reviews state
      setReviews((prevReviews) => [...(Array.isArray(prevReviews) ? prevReviews : []), newReview]);
  
      // Reset form
      resetForm();
    } catch (error) {
      console.error("Error submitting review:", error);
      setErrors({ submit: "Failed to submit review." });
    } finally {
      setSubmitting(false);
    }
  };
  
  
  

  return (
    <Box className={classes.commentSection}>
      <Typography variant="h5" className={classes.reviewsTitle}>
        Reviews
      </Typography>

         {/* Existing reviews */}
         {reviews.length > 0 ? (
        reviews.map((review, index) => (
          <Box key={index} className={classes.reviewBox} display={"flex"} >
            <Box mt={0.5} >
            <Avatar/>
            </Box>
            <Box ml={1}>
            <Typography variant="body1" sx={{ml:0.5}}>{review.comment}</Typography>
            <Rating value={review.rating} readOnly />
            </Box>
          </Box>
        ))
      ) : (
        <Typography>No reviews yet.</Typography>
      )}
      {/* Formik form */}
      <Formik
        initialValues={{ comment: "", rating: 0 }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form>
            {/* Rating input */}
            <Box className={classes.rating}>
              <Typography variant="body1">Your Rating:</Typography>
              <Rating
                name="rating"
                value={values.rating}
                onChange={(event, newValue) => setFieldValue("rating", newValue)}
              />
              <ErrorMessage name="rating" component="div" className={classes.errorMessage} />
            </Box>

            {/* Comment input */}
            <Field name="comment">
              {({ field, form }) => (
                <TextField
                  {...field}
                  placeholder="Write a comment"
                  multiline
                  rows={4}
                  fullWidth
                  className={classes.commentInput}
                  error={Boolean(form.errors.comment && form.touched.comment)}
                  helperText={<ErrorMessage name="comment" />}
                />
              )}
            </Field>

            {/* Submit button */}
            <Button sx={{mt:1}}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Submit Review
            </Button>
            <ErrorMessage name="submit" component="div" className={classes.errorMessage} />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Reviews;
