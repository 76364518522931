import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/SALEECA.png";
import saleeca_Logo from "../../assets/images/SALEECA.png";
import { useCart } from '../../Contexts/productContext'; // Import the useCart hook

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    color: '#FFF',
    boxShadow: 'none',
    transition: 'top 0.5s, background-color 0.5s, color 0.5s',
    top: 0,
    zIndex: 9999,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px'
  },
  navLinks: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    }
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    width: 240,
  },
}));

function NavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  const { getCartCount } = useCart(); // Use the cart context

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleNavigationItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setSelectedNavItem(id);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
  
    if (currentScrollPos > 50) {
      setScrolled(true);
      setVisible(false);  // Hide navbar after scrolling past 100px
    } else {
      setScrolled(false);
      setVisible(true);  // Show navbar when at the top
    }
  
    setPrevScrollPos(currentScrollPos);
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const drawerContent = (
    <div className={classes.drawer}>
      <List>
        {['home', 'best-sellers', 'testimonials'].map((text) => (
          <ListItem button key={text} onClick={() => handleNavigationItemClick(text)}>
            <ListItemText primary={text.charAt(0).toUpperCase() + text.slice(1).replace(/-/g, ' ')} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar   position="fixed"
    sx={{
      top: visible ? 0 : -100, // Move out of view when not visible
      backgroundColor: scrolled ? '#C79570' : '#C79570',
      color: scrolled ? 'white' : '#FFF',
      transition: 'top 0s', // Smooth transition for hiding
    
    }}>
           <Box
  sx={{
    backgroundColor: "#4b3539",
    height: "30px",
    color: "white",
    width: "100vw",           // Full screen width
    mx: 0,                    // Remove margins
    position: "fixed",         // Fixed position (optional)
    top: 0,                    // Place it at the top (optional)
    zIndex: 10000,             // Make sure it's above other elements (optional)
    display: "flex",           // Use flexbox
    alignItems: "center",      // Center vertically
    justifyContent: "center"   // Center horizontally
  }}
>
  Free shipping on orders above 2999/-
</Box>
      <Toolbar className={classes.toolbar}>
      <Box sx={{ marginRight: "2%", marginLeft: "4%", height: "80px", mt:2, display: isTablet ? 'none' : 'block' }} >
  <img src={scrolled ? saleeca_Logo : Logo} alt="logo" style={{ width: "100px" }} onClick={() => navigate("/")}/>
</Box>


        {isTablet ? (
          <>
           <Box sx={{ marginRight: "2%", marginLeft: "4%", height: "80px", mt:2, display: isTablet ? 'block' : 'none' }} >
  <img src={scrolled ? saleeca_Logo : Logo} alt="logo" style={{ width: "100px" }} onClick={() => navigate("/")}/>
</Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%',mt:4 }}>
      <IconButton color="brown">
        <Badge badgeContent={getCartCount()} color="secondary">
          <ShoppingCart onClick={() => navigate("/checkout")} />
        </Badge>
      </IconButton>
    </Box>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
              {drawerContent}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" ,mt:4}}>
            <Box>
              <Button color="inherit"
                sx={{
                  textTransform: 'capitalize',
                  letterSpacing: "1px",
                  fontSize: "15px",
                  color: scrolled ? '#3b2a2d' : '#3b2a2d',
                }}
                onClick={() => navigate("/")}
              >
                Home
              </Button>
              <Button color="inherit"
                sx={{
                  textTransform: 'capitalize',
                  letterSpacing: "1px",
                  fontSize: "15px",
                  color: scrolled ? '#3b2a2d' : '#3b2a2d',
                }}
                onClick={() => navigate("/products")}
              >
                Shop
              </Button>
              <Button color="inherit"
                sx={{
                  textTransform: 'capitalize',
                  letterSpacing: "1px",
                  fontSize: "15px",
                  color: scrolled ? '#3b2a2d' : '#3b2a2d',
                }}
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </Button>
            </Box>
            <Box>
              <IconButton color="brown" onClick={handleDrawerOpen}>
                <Badge badgeContent={getCartCount()} color="secondary">
                  <ShoppingCart onClick={() => navigate("/checkout")} />
                </Badge>
              </IconButton>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
