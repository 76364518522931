import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContactApi } from "../../../apis";
import DeleteIcon from '@mui/icons-material/Delete';

function Services() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ContactApi.getcontact();
        setData(response.data.contacts);
      } catch (error) {
        setError('Failed to fetch contacts.');
        console.error("Error fetching contacts:", error);
      }
    };
    
    fetchData();
  }, []);
  const handleDelete = (id) => {
    ContactApi.deleteContact(id)
      .then((response) => {
        console.log(`Contact with ID ${id} deleted successfully.`, response);
        // Remove the deleted coupon from the state immediately
        setData((prevData) => prevData.filter((contact) => contact.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting Contact:", error);
      });
  };
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            View Contacts
          </Typography>
        </Box>
        {error && (
          <Typography color="error" align="center" sx={{ marginBottom: '20px' }}>
            {error}
          </Typography>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <table className="table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ paddingRight: "20px" }}>Contact ID</th>
                  <th style={{ paddingLeft: "40px" }}>Name</th>
                  <th style={{ paddingLeft: "40px" }}>Email</th>
                  <th style={{ paddingLeft: "40px" }}>Phone</th>  
                  <th style={{ paddingLeft: "40px" }}>Message</th>
                  <th style={{ paddingLeft: "40px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data ? (
                  data.map((contact) => (
                    <tr key={contact.id}>
                      <td style={{ paddingLeft: "40px" }}>{contact.id}</td>
                      <td style={{ paddingLeft: "40px" }}>{contact.name}</td>
                      <td style={{ paddingLeft: "40px" }}>{contact.email}</td>
                      <td style={{ paddingLeft: "40px" }}>{contact.phone}</td>
                      <td style={{ paddingLeft: "40px" }}>{contact.message}</td>
                      <td>
                        <ButtonGroup variant="text" aria-label="large button group" sx={{ paddingLeft: 2 }}>
                         
                          <Button onClick={() => handleDelete(contact.id)}>
                            <DeleteIcon color="error" />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>No Contacts available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Services;
