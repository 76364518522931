import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, IconButton, Button, Collapse, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { ProductsApi } from '../../apis'; // Import your API
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    marginTop: "150px",
    minHeight: '150vh',
    flexDirection: 'column',
  },
  sidebar: {
    width: '250px',
    paddingRight: theme.spacing(2),
    borderRight: '1px solid #ddd',
    position: 'sticky',
    top: 0,
    height: '150vh',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide sidebar on small screens
    },
  },
  // your existing styles here
  pagination: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(1),
      
    },
  },
  sidebarOpen: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      zIndex: 1200,
      backgroundColor: '#fff', // Add background to cover content behind
    },
  },
  sidebarTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontSize: '1.2rem',
    padding: theme.spacing(2),
    backgroundColor: '#eee',
    borderBottom: '1px solid #ddd',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center', // Center the title on xs screens
    },
  },
  
  sidebarList: {
    listStyleType: 'none',
    paddingLeft: 0,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    '& li': {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      cursor: 'pointer',
      borderRadius: '4px',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: '#ddd',
      },
    },
  },
  nestedList: {
    paddingLeft: theme.spacing(4),
    fontSize: '1rem', // Smaller font size for subtypes
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s',
    '&.open': {
      transform: 'rotate(180deg)', // Rotate when open
    },
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'absolute',
      top: theme.spacing(-5),
      right: theme.spacing(2),
      zIndex: 1300,
    },
  },
  gridContainer: {
    flex: 1,
    paddingLeft: theme.spacing(2),
  },
  card: {
    border: '1px solid gray',
    textAlign: 'center',
    height: '350px', // Ensures square shape
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Space between image and text
    transition: 'transform 0.3s ease-in-out', // Animation on hover
    '&:hover': {
      transform: 'scale(1.05)', // Slightly increase size on hover
    },
  },
  image: {
    width: '100%',
    height: '70%', // Occupies 70% of the card height
    objectFit: 'cover', // Ensures the image covers the entire area without distortion
  },
  stepTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: 'black',
  },
  stepDescription: {
    fontSize: '1rem',
    color: '#333',
    marginBottom: theme.spacing(1),
  },
  filterSection: {
    marginBottom: theme.spacing(0),
  },
}));

const subTypeOptions = ["Earrings", "Rings", "Pendants", "Anklets", "Matha Pati", "Bindiya", "Bangles", "Bracelets"];

const ProductPage = () => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState('All'); // Default to 'All'
  const [selectedSubType, setSelectedSubType] = useState(''); // Track the selected sub-type
  const [jewelryOpen, setJewelryOpen] = useState(false); // Manage dropdown state
  function multiParse(input) {
    let result = input;
    try {
        while (typeof result === 'string') {
            result = JSON.parse(result);
        }
    } catch (e) {
        console.error('Error parsing input:', e);
    }
    return result;
}

// Fetch products from API
useEffect(() => {
    ProductsApi.getProducts()
      .then((res) => {
        // Ensure res.data.products is an array
        const parsedData = res.data.products.map((service) => {
          let images = [];
          let addOns = [];
          
          // Safely parse images
          try {
              images = multiParse(service.images || "[]");
          } catch (e) {
              console.error('Error parsing images:', e);
          }

          // Safely parse add-ons
          try {
              addOns = multiParse(service.addOns || "[]");
          } catch (e) {
              console.error('Error parsing add-ons:', e);
          }

          return { ...service, images, addOns };
        });
        
        // Update the products and filteredProducts state
        setProducts(parsedData);
        setFilteredProducts(parsedData); // Initialize with all products
      })
      .catch((error) => console.error("Error fetching products:", error));
}, []);


  // Filter products based on selected type and sub-type
  useEffect(() => {
    let filtered = products;

    // Filter by type
    if (selectedType !== 'All') {
      filtered = filtered.filter((product) => product.type === selectedType);
    }

    // Further filter by sub-type if selected
    if (selectedSubType) {
      filtered = filtered.filter((product) => product.sub_type === selectedSubType);
    }

    setFilteredProducts(filtered);
  }, [selectedType, selectedSubType, products]);

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handleNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  const handlePrevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  // Handle type selection (e.g., Jewelry, Bags, etc.)
// Handle the click on the main jewelry type
const handleJewelryClick = () => {
  // Toggle the dropdown visibility
  setJewelryOpen(!jewelryOpen);

  // If jewelry is not already selected, select it and reset sub-type
  if (selectedType !== 'Jewelry') {
    setSelectedType('Jewelry');
    setSelectedSubType(''); // Reset sub-type when changing the main type
  }
};

// Handle type click (for other types)
const handleTypeClick = (type) => {
  setSelectedType(type); // Set the selected type
  setSelectedSubType(''); // Reset sub-type when type changes
  setJewelryOpen(false);  // Close the jewelry dropdown if any other type is selected
};

// Handle sub-type selection (e.g., Earrings, Rings, etc.)
const handleSubTypeClick = (subType) => {
  setSelectedSubType(subType); // Set the selected sub-type
};


  // Render component
  return (
    <Box sx={{ display: 'flex', marginTop: '200px', mb: 6, position: 'relative' }}>
     {isSmallScreen && (
      <>
        <IconButton
          className={classes.menuButton}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          color="primary"
        >
          {sidebarOpen ? (
            <CloseIcon sx={{ mb: "auto", position: "absolute", top: -90, left: 10 }} />
          ) : (
            <MenuIcon sx={{ mb: "auto", position: "absolute", top: -56, left: 10 }} />
          )}
        </IconButton>

        {/* Show the ArrowBackIcon only when the sidebar is closed */}
        {!sidebarOpen && (
          <ArrowBackIcon
            sx={{ mb: "auto", position: "absolute", top: -30, left: 12 ,color:"gray"}}
            onClick={() => navigate("/")} // Navigate back to the previous page
          />
        )}
      </>
    )}

      <Box className={`${classes.sidebar} ${sidebarOpen ? classes.sidebarOpen : ''}`} sx={{ display: { xs: sidebarOpen ? 'block' : 'none', sm: 'block' } }}>
        <Typography variant="h6" className={classes.sidebarTitle}>Categories</Typography>
        <List className={classes.sidebarList}>
          {/* All Products */}
          <ListItem button onClick={() => handleTypeClick('All')}   style={{ color: selectedType=="All" ? '#C79570' : 'black' }}>
            <ListItemText primary="All Products" />
          </ListItem>

          {/* Jewelry */}
          <ListItem button onClick={handleJewelryClick} style={{ color: selectedType=="Jewelry" ? '#C79570' : 'black' }}>
            <ListItemText primary="Jewelry" />
            <ExpandMore className={`${classes.expandIcon} ${jewelryOpen ? 'open' : ''}`} />
          </ListItem>
          <Collapse in={jewelryOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.nestedList}>
              {subTypeOptions.map((subType) => (
                <ListItem button key={subType} onClick={() => handleSubTypeClick(subType)}>
                  <ListItemText primary={subType} style={{ color: selectedSubType==subType ? '#C79570' : 'black' }}/>
                </ListItem>
              ))}
            </List>
          </Collapse>

          {/* Bags */}
          <ListItem button onClick={() => handleTypeClick('Bags')} style={{ color: selectedType=='Bags' ? '#C79570' : 'black' }}>
            <ListItemText primary="Bags" />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ flex: 1, paddingLeft: { sm: '26px' } }}>
    
        {/* Check if there are no products */}
        {filteredProducts.length === 0 ? (
          <Typography variant="h6" className={classes.noProductsMessage}>
            No products available
          </Typography>
        ) : (
          <>
           <Box sx={{height: { sm: '100%',mt:3 } }}>
            <Grid container spacing={2}>
      {currentProducts.map((product, index) => {
        // Check if product.images exists and is an array
        const imageUrl = 
          Array.isArray(product.images) && product.images.length > 0
            ? `${process.env.REACT_APP_API_BASE_URL}/public/image/${product.images[0]}`
            : `${process.env.REACT_APP_API_BASE_URL}/public/image/${product.images}`; // Fallback image

        return (
          <Grid item xs={6} sm={6} md={3} key={index} >
            <Card className={classes.card} onClick={() => navigate(`/product/${product.id}`)}>
            <CardContent sx={{ padding: 0 }}>
            <img
                  src={imageUrl}
                  alt={product.title || 'Product Image'}
                  style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop in case of multiple errors
                    e.target.src = 'path/to/placeholder/image.jpg'; // Fallback image
                  }}
                  
                />
     

                <Typography variant="subtitle1" color="black">
                  {product.title}
                </Typography>
                
                <div>
  {product.discounted_price ? (
    <>
           {product.show_price === "active" || product.show_price === "Active" && (

        <Typography
          variant="subtitle1"
          color="black"
          style={{ textDecoration: 'line-through', marginRight: '8px', color: "grey" }}
        >
          PKR {product.price}
        </Typography>
      )}
     <Typography 
  variant="subtitle1" 
  color="#f57c00" 
  mt={product.show_price === "active" || product.show_price === "Active" ? -1 : 1}
>
  PKR {product.discounted_price}
</Typography>

    </>
  ) : (
    <Typography variant="subtitle1" color="#f57c00">
      PKR {product.price}
    </Typography>
  )}
</div>

    
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>

            </Box>

            {/* Pagination */}
            <Box className={classes.pagination}>
              <Button variant="contained" onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Typography variant="body1" sx={{ mx: 2 }}>{`Page ${currentPage} of ${totalPages}`}</Typography>
              <Button variant="contained" onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProductPage;
