import React, { useState } from 'react';
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  AppBar,
  Button,
  ListItemButton,
} from '@mui/material';
import ProductionQuantityLimitsRoundedIcon from '@mui/icons-material/ProductionQuantityLimitsRounded';
import Discount from '@mui/icons-material/Discount';
import Shipping from '@mui/icons-material/LocalShipping';
import Contact from '@mui/icons-material/ContactPage';
import Reviews from '@mui/icons-material/RateReview';
import Banner from '@mui/icons-material/Photo';
import AppRoutes from '../../router/AdminRoutes/index'; // Import the AppRoutes component
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/SALEECA.png';

const drawerWidth = 240;

const AdminDashboard = () => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState('products');

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
    window.location.reload();
  };

  const renderComponent = () => {
    if (isAuthenticated()) {
      return (
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              bgcolor: 'primary.main',
            }}
          >
            <Toolbar>
              <Typography variant="h6" noWrap component="div" color="secondary.main">
                {/* Add app title here if needed */}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'secondary.main' },
            }}
          >
            <Box sx={{ backgroundColor: 'secondary.main', height: '9%' }}>
              <img src={logo} alt="Logo" style={{ width: '200px', padding: '20px', marginLeft: '15px' }} />
            </Box>
            <Toolbar />
            <Box sx={{ overflow: 'auto', mt: 5 }}>
              <List>
                {/* Products */}
                <ListItemButton
                  selected={selectedMenuItem === 'products'}
                  onClick={() => {
                    setSelectedMenuItem('products');
                    navigate('/v1/products/detail');
                  }}
                >
                  <ListItemIcon>
                    <ProductionQuantityLimitsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Products" />
                </ListItemButton>

                {/* Banner */}
                <ListItemButton
                  selected={selectedMenuItem === 'banner'}
                  onClick={() => {
                    setSelectedMenuItem('banner');
                    navigate('/v1/banner');
                  }}
                >
                  <ListItemIcon>
                    <Banner />
                  </ListItemIcon>
                  <ListItemText primary="Banner" />
                </ListItemButton>

                {/* Contacts */}
                <ListItemButton
                  selected={selectedMenuItem === 'contacts'}
                  onClick={() => {
                    setSelectedMenuItem('contacts');
                    navigate('/v1/contacts');
                  }}
                >
                  <ListItemIcon>
                    <Contact />
                  </ListItemIcon>
                  <ListItemText primary="Contacts" />
                </ListItemButton>

                {/* Reviews */}
                <ListItemButton
                  selected={selectedMenuItem === 'reviews'}
                  onClick={() => {
                    setSelectedMenuItem('reviews');
                    navigate('/v1/reviews');
                  }}
                >
                  <ListItemIcon>
                    <Reviews />
                  </ListItemIcon>
                  <ListItemText primary="Reviews" />
                </ListItemButton>

                {/* Shipping */}
                <ListItemButton
                  selected={selectedMenuItem === 'shipping'}
                  onClick={() => {
                    setSelectedMenuItem('shipping');
                    navigate('/v1/shipping');
                  }}
                >
                  <ListItemIcon>
                    <Shipping />
                  </ListItemIcon>
                  <ListItemText primary="Shipping" />
                </ListItemButton>
                <ListItemButton
                  selected={selectedMenuItem === 'coupon'}
                  onClick={() => {
                    setSelectedMenuItem('coupon');
                    navigate('/v1/coupon');
                  }}
                >
                  <ListItemIcon>
                    <Discount />
                  </ListItemIcon>
                  <ListItemText primary="Coupon" />
                </ListItemButton>
                {/* Checkout
                <ListItemButton
                  selected={selectedMenuItem === 'checkout'}
                  onClick={() => {
                    setSelectedMenuItem('checkout');
                    navigate('/v1/checkout');
                  }}
                >
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Checkout" />
                </ListItemButton> */}
              </List>
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            <Toolbar />
            <AppRoutes />
          </Box>
        </>
      );
    } else {
      return null; // Render nothing if not authenticated
    }
  };

  return <Box sx={{ display: 'flex' }}>{renderComponent()}</Box>;
};

export default AdminDashboard;
