import axios from "../axiosConfig";

class Routes {
  // get all services
  getcart = () => {
    let data = axios.get("/cart/");
    return data;
  };
  ViewCart = () => {
    let data = axios.get("/cart/get");
    return data;
  };
  // get service by id
  getcartById = (id) => {
    let data = axios.get(`/cart/get/${id}`);
    return data;
  };
  // /add-cart/detail
  addcart = (data) => {
    let response = axios.post("/cart/add", data);
    return response;
  };
  // update service by id
  updatecart = (id, data) => {
    let response = axios.post(`/cart/update/${id}`, data);
    return response;
  };
  deletecart = (id) => {
    let response = axios.delete(`/cart/remove/${id}`);
    return response;
  };

}

export default new Routes();
