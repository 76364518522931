import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/SALEECA.png";
import saleeca_Logo from "../../assets/images/SALEECA.png";
import { useCart } from '../../Contexts/productContext'; // Import the useCart hook
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    color: '#FFF',
    boxShadow: 'none',
    transition: 'top 0.5s, background-color 0.5s, color 0.5s',
    top: 0,
    zIndex: 9999,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px'
  },
  navLinks: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    }
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop:-15
  },
  drawer: {
    width: 240,
  },
}));

function NavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  const { getCartCount } = useCart(); // Use the cart context

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleNavigationItemClick = (id) => {
    if (id === 'home') {
      // Scroll to the top of the page or a specific section
      window.scrollTo(0, 0);
    } else if (id === 'products') {
      // Navigate to the Products page
      navigate('/products');
    } else if (id === 'testimonials') {
      // Scroll to testimonials section (if you have an element with id 'testimonials')
      const element = document.getElementById('testimonials');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    
    setScrolled(currentScrollPos > 100);

    if (isTablet) {
      if (currentScrollPos > prevScrollPos) {
        setVisible(true);
      } else if (currentScrollPos < prevScrollPos) {
        setVisible(true); 
      }

      if (currentScrollPos <= 100) {
        setVisible(true); // If at the top of the page, show the navbar
      }
    } else { // Desktop mode logic
      if (currentScrollPos > 100 && currentScrollPos > prevScrollPos) {
        setVisible(true); // If scrolling down on desktop, show the navbar
      } else if (currentScrollPos > 100 && currentScrollPos < prevScrollPos) {
        setVisible(true); // If scrolling up on desktop, show the navbar
      } else if (currentScrollPos <= 100) {
        setVisible(true); // If at the top of the page, show the navbar
      }
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);
  const iconColor = scrolled ? '#4b3539' : '#FFFFFF'; // Brown when scrolled, white when at top
  const drawerContent = (
    <div className={classes.drawer}>
      <List>
        <ListItem button onClick={() => handleNavigationItemClick('home')}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => handleNavigationItemClick('products')}>
          <ListItemText primary="Shop Now" />
        </ListItem>
        <ListItem button onClick={() => handleNavigationItemClick('testimonials')}>
          <ListItemText primary="Testimonials" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar 
      className={classes.appBar} 
      elevation={0}
      style={{ 
        top: visible ? 0 : -100, 
        backgroundColor: scrolled ? '#C79570' : 'transparent', 
        color: scrolled ? theme.palette.primary.main : '#FFF' 
      }}
    >
           <Box
  sx={{
    backgroundColor: "#4b3539",
    height: "30px",
    color: "white",
    width: "100vw",           // Full screen width
    mx: 0,                    // Remove margins
    position: "fixed",         // Fixed position (optional)
    top: 0,                    // Place it at the top (optional)
    zIndex: 10000,             // Make sure it's above other elements (optional)
    display: "flex",           // Use flexbox
    alignItems: "center",      // Center vertically
    justifyContent: "center"   // Center horizontally
  }}
>
  Free shipping on orders above 2999/-
</Box>
      <Toolbar className={classes.toolbar}>
        {isTablet&&   <IconButton color="inherit" onClick={handleDrawerOpen}>
                <MenuIcon />
              </IconButton> }
        <Box sx={{ marginRight: "2%",marginRight: {xs:"auto"},marginLeft: {lg:"4%"},height: "80px" ,mt:2}} >
          <img src={scrolled ? saleeca_Logo : Logo} alt="logo" style={{ width: "100px" }} onClick={() => navigate("/")}/>
        </Box>
        {isTablet ? (
          <>
            <div className={classes.actionButtons}>
              
            <Box sx={{ display: 'block', mt: 2, color: 'white' }}>
      <div>
        <a href="https://wa.me/+923079566695" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white', fontSize: isTablet ? 20 : 30 ,color: iconColor}}>
            <WhatsAppIcon sx={{ fontSize: isTablet ? 20 : 30 }} />
          </IconButton>
        </a>
        <a href="https://web.facebook.com/people/Saleeca/100087290356157/" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white', fontSize: isTablet ? 20 : 30 ,color: iconColor}}>
            <FacebookIcon sx={{ fontSize: isTablet ? 20 : 30 }} />
          </IconButton>
        </a>
        <a href="https://www.instagram.com/saleecaofficial/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white', fontSize: isTablet ? 20 : 30 ,color: iconColor}}>
            <InstagramIcon sx={{ fontSize: isTablet ? 20 : 30 }} />
          </IconButton>
        </a>
        <a href="https://www.linkedin.com/company/103219537/admin/dashboard/" target="_blank" rel="noopener noreferrer">
        
          <IconButton sx={{ color: 'white', fontSize: isTablet ? 20 : 30 ,color: iconColor}}>
            <LinkedInIcon sx={{ fontSize: isTablet ? 20 : 30 }} />
          </IconButton>
        </a>
      </div>
    </Box>
      
            </div>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
              {drawerContent}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",mt:4 }}>
            <Box>
              <Button color="inherit"
                sx={{
                  textTransform: 'capitalize',
                  letterSpacing: "1px",
                  fontSize: "15px",
                  color: scrolled ? "#4b3539" : '#FFF',
                }}
                onClick={() => handleNavigationItemClick('home')}
              >
                Home
              </Button>
              <Button color="inherit"
                sx={{
                  textTransform: 'capitalize',
                  letterSpacing: "1px",
                  fontSize: "15px",
                  color: scrolled ? "#4b3539" : '#FFF',
                }}
                onClick={() => handleNavigationItemClick('products')}
              >
                Shop Now
              </Button>
              <Button color="inherit"
                sx={{
                  textTransform: 'capitalize',
                  letterSpacing: "1px",
                  fontSize: "15px",
                  color: scrolled ? "#4b3539" : '#FFF',
                }}
                onClick={() => handleNavigationItemClick('testimonials')}
              >
                Testimonials
              </Button>
            </Box>
            <Box className={classes.actionButtons}>
              {/* <IconButton color="inherit" onClick={() => navigate("/checkout")}>
                <Badge badgeContent={getCartCount()} color="secondary">
                  <ShoppingCart />
                </Badge>
              </IconButton> */}
            <Box  sx={{ display: 'block', mt: 2 , color: "white",}}>
      <div>
      <a href="https://wa.me/+923079566695" target="_blank" rel="noopener noreferrer">
        <IconButton sx={{ color: iconColor }}>
          <WhatsAppIcon />
        </IconButton>
      </a>
      <a href="https://web.facebook.com/people/Saleeca/100087290356157/" target="_blank" rel="noopener noreferrer">
        <IconButton sx={{ color: iconColor }}>
          <FacebookIcon />
        </IconButton>
      </a>
      <a href="https://www.instagram.com/saleecaofficial/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target="_blank" rel="noopener noreferrer">
        <IconButton sx={{ color: iconColor }}>
          <InstagramIcon />
        </IconButton>
      </a>
      <a href="https://www.linkedin.com/company/103219537/admin/dashboard/" target="_blank" rel="noopener noreferrer">
        <IconButton sx={{ color: iconColor,mr:5 }}>
          <LinkedInIcon />
        </IconButton>
      </a>
    </div>
        </Box>
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
