import React, { createContext, useState, useContext, useEffect } from 'react';

// Modal Component
const Modal = ({ message, onClose }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.modalContent}>
        <p>{message}</p>
        <button onClick={onClose} style={styles.closeButton}>Close</button>
      </div>
    </div>
  );
};

// Styles for the Modal
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    minWidth: '300px',
  },
  closeButton: {
    marginTop: '10px',
    padding: '8px 16px',
    border: 'none',
    backgroundColor: '#834F37',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '5px',
  },
};

// Create a Context
const CartContext = createContext();

// Create a Provider component
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedItems = sessionStorage.getItem('cartItems');
    return savedItems ? JSON.parse(savedItems) : [];
  });

  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(""); 
  const [errorMessage, setErrorMessage] = useState("");
  const [activeItemId, setActiveItemId] = useState(null);
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    city: '',
    postalCode: '',
    phone: '',
    paymentMethod: 'cod',
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [modalMessage, setModalMessage] = useState(""); // Modal message state

  useEffect(() => {
    sessionStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const removeFromCart = (productId) => {
    setCartItems(cartItems.filter(item => item.id !== productId));
  };

  const increaseQuantity = (productId) => {
    setActiveItemId(productId); 
    setCartItems(prevItems => {
      return prevItems.map(item => {
        if (item.id === productId) {
          if (item.quantity < item.max_quantity) {
            setErrorMessage(""); 
            return { ...item, quantity: item.quantity + 1 }; 
          } else {
            setErrorMessage("Out of Stock"); 
            return item; 
          }
        }
        return item;
      });
    });
  };

  const decreaseQuantity = (productId) => {
    setActiveItemId(productId); 
    setCartItems(prevItems => {
      return prevItems.map(item => {
        if (item.id === productId) {
          if (item.quantity > 0) {
            setErrorMessage(""); 
            return { ...item, quantity: item.quantity - 1 };
          } else {
            setErrorMessage("");
            return item;
          }
        }
        return item;
      });
    });
  };

  const addToCart = (product) => {
    console.log("Adding product to cart:", product);
  
    setCartItems((prevItems) => {
      console.log("Current cart items:", prevItems);
  
      // Find an existing item that matches by both `id` and `selectedColor`
      const existingItem = prevItems.find(
        (item) => item.id === product.id && item.selectedColor === product.selectedColor
      );
  
      if (existingItem) {
        console.log("Existing item found:", existingItem);
        const newQuantity = existingItem.quantity + product.quantity;
        console.log("New quantity after adding:", newQuantity);
  
        if (newQuantity <= existingItem.max_quantity) {
          console.log("Quantity is within the limit. Updating cart...");
          const updatedItems = prevItems.map((item) =>
            item.id === product.id && item.selectedColor === product.selectedColor
              ? {
                  ...item,
                  quantity: newQuantity,
                  max_quantity: existingItem.max_quantity,
                }
              : item
          );
          console.log("Updated cart items:", updatedItems);
          return updatedItems;
        }
  
        console.log(
          `Cannot add more items. Only ${existingItem.max_quantity - existingItem.quantity} left in stock.`
        );
        setModalMessage(
          `Cannot add more items. Only ${existingItem.max_quantity - existingItem.quantity} left in stock.`
        );
        setIsModalOpen(true);
        return prevItems;
      }
  
      // For adding a new product
      if (product.quantity <= product.max_quantity) {
        console.log("Adding new item to the cart:", product);
        const newItem = {
          ...product,
          max_quantity: product.max_quantity,
        };
        const updatedCart = [...prevItems, newItem];
        console.log("Updated cart items after adding new item:", updatedCart);
        return updatedCart;
      } else {
        console.log(
          `Cannot add more items. Only ${product.max_quantity} left in stock.`
        );
        setModalMessage(`Cannot add more items. Only ${product.max_quantity} left in stock.`);
        setIsModalOpen(true);
        return prevItems;
      }
    });
  };
  
  
  

  const getDeliveryFee = () => {
    const totalPrice = getTotalPrice();
    return totalPrice > 2999 ? 0 : 199;
  };

  const getCartCount = () => cartItems.length;

  const getTotalPrice = () => {
    const totalPrice = cartItems.reduce(
      (total, item) => total + parseFloat(item.discounted_price || 0) * (item.quantity || 1),
      0
    );
    const finalPrice = discount ? totalPrice * (1 - discount / 100) : totalPrice;
    return parseFloat(finalPrice.toFixed(2));
  };

  const getOriginalTotalPrice = () => {
    return cartItems.reduce((total, item) => total + parseFloat(item.discounted_price) * item.quantity, 0);
  };

  const getDiscountedPrice = () => {
    const totalPrice = getOriginalTotalPrice();
    if (discount && typeof discount === 'number') {
      const discountAmount = (totalPrice * discount) / 100;
      return (totalPrice - discountAmount).toFixed(2);
    }
    return totalPrice.toFixed(2);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage('');
  };

  return (
    <CartContext.Provider value={{
      errorMessage, setErrorMessage, activeItemId, setActiveItemId,
      discount, setDiscount, coupon, setCoupon, cartItems, setCartItems,
      formData, getCartCount, setFormData, removeFromCart, increaseQuantity,
      getDeliveryFee, decreaseQuantity, addToCart, getTotalPrice, getOriginalTotalPrice,
      getDiscountedPrice
    }}>
      {children}

      {/* Show Modal if there's a message */}
      {isModalOpen && <Modal message={modalMessage} onClose={closeModal} />}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => useContext(CartContext);
