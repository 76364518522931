import React from "react";
import NavBar from "../../components/LandingPage/navBar";
import Banner from "../../components/LandingPage/Banner";
import TopFeatured from "../../components/LandingPage/TopFeatured";
import BagSection from "../../components/LandingPage/BagSection";
import JewlerySection from "../../components/LandingPage/JewlerySection";
import EndingPage from "../../components/LandingPage/EndingPage";
import TestimonialSection from "../../components/LandingPage/TestimonialSection";
import Footer from "../../components/LandingPage/Footer";
import { Box, Container } from "@mui/material";

export default function LandingPage() {

    return (
        <>

            <section id="home">
 

                <NavBar />
                <Banner />
            </section>
            <Container
                maxWidth="1600"
                sx={{
                    maxWidth: "1600px",
                   
                }}

                style={{  padding: 0 }}
            >
               
                <section id="how-it-works">
                <TopFeatured />
                </section>
          
              
               
                <BagSection />
                <JewlerySection />
               
                <section id="testimonials">
                    <TestimonialSection />
                </section>
                <EndingPage />
            </Container>
            <Footer />
        </>


    );
}

