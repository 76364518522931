import axios from "../axiosConfig";

class Routes {

 
    login= (val) => {
        let data = axios.post("/admin-auth/login", val);
        return data;
    };
 
}



export default new Routes();