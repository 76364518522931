import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { BannerApi } from "../../../apis";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

function Services() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  function multiParse(input) {
    let result = input;
    try {
        while (typeof result === 'string') {
            result = JSON.parse(result);
        }
    } catch (e) {
        console.error('Error parsing input:', e);
    }
    return result;
}

  useEffect(() => {
    BannerApi.getBanner().then((res) => {
      const parsedData = multiParse(res.data.banners); // Call multiParse here
      setData(parsedData);
    });
  }, []);

  const handleDelete = (id) => {
    // Uncomment and implement delete functionality as needed
    // BannerApi.delete(id)
    //   .then(() => {
    //     console.log(`Service with ID ${id} deleted successfully.`);
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting service:", error);
    //   });
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              View Banner
            </Typography>
          </Box>
         
        </Box>
        <Grid container spacing={2} pt={5}>
          <Grid xs={12}>
            <table className="table" style={{ width: "100%", tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th style={{ width: "15%", textAlign: "left", paddingLeft: "10px" }}>Banner ID</th>
                  <th style={{ width: "70%", textAlign: "center", paddingLeft: "10px" }}>Image</th>
                  <th style={{ width: "15%", textAlign: "center", paddingLeft: "10px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((service, index) => ( 
                    <tr key={index}>
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>{service.id}</td>
                      <td style={{ textAlign: "center", paddingLeft: "10px" }}>
                        {service.image && (
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${multiParse(service.image)}`}
                      alt={service.image}
                      style={{ width: "150px", height: "100px", objectFit: "cover" }}
                    />
                          </Box>
                        )}
                      </td>
                      <td style={{ textAlign: "center", paddingLeft: "10px" }}>
                        <ButtonGroup variant="text" aria-label="large button group">
                          <Button style={{ color: 'grey' }} onClick={(event) => { event.stopPropagation();  navigate("/v1/add-banner", { state: { id:service.id } }); }}>
                            <BorderColorIcon />
                          </Button>
                          {/* <Button onClick={() => handleDelete(service.id)}>
                            <DeleteIcon color="error" />
                          </Button> */}
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>No banner available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Services;
