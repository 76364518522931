import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import * as yup from "yup";
import { ProductsApi,UploadImageApi } from "../../../apis";
import { makeStyles } from "@mui/styles";
import imageCompression from 'browser-image-compression'; // Import the compression library

const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
  },
});
const typeOptions = ["Jewelry", "Bags"];
const subTypeOptions = ["Earrings", "Rings", "Pendants", "Anklets", "Matha Pati", "Bindiya", "Bangles", "Bracelets"];
const validationSchema = yup.object({
  price: yup.number().required("Price is required").positive("Price must be positive"),
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  addOns: yup.array().of(
    yup.object().shape({
      key: yup.string().required("Add-on key is required"),
      price: yup.number().required("Add-on price is required").positive("Price must be positive"),
    })
  ),
  image: yup.mixed().required("Image is required"),
});

const EditProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { serviceId } = state || {};
  
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageColors, setImageColors] = useState([]);
  const [addOns, setAddOns] = useState([{ key: "", price: "" }]);

  const classes = useStyles();
  const ColorSelector = ({ selectedColor, onColorChange }) => {
    return (
      <div>
        <TextField
  
          value={selectedColor}
          onChange={onColorChange}
          variant="outlined"
          fullWidth
          placeholder="Type color name"
          autoFocus
        />
        {selectedColor && (
          <div>
            <p>
              You entered: <strong>{selectedColor}</strong>
            </p>
            <div
              style={{
                width: "100px",
                height: "100px",
                backgroundColor: selectedColor,
                marginTop: "10px",
              }}
            ></div>
          </div>
        )}
      </div>
    );
  };
  useEffect(() => {
    if (id) {
      ProductsApi.getProductsById(id).then((res) => {
        const product = res.data.product;
  
        // Apply multiParse function to fields that might need multiple parsing
        formik.setValues({
          serviceId: serviceId || "",
          price: product.price,
          show_price: product.show_price,
          discounted_price: product.discounted_price,
          quantity: product.quantity,
          type: product.type,
          sub_type: product.sub_type,
          top_featured: product.top_featured,
          imageColors: multiParse(product.imageColors || "[]"), // Apply multiParse for nested parsing
          title: product.title,
          description: product.description,
          addOns: multiParse(product.addOns || "[]"), // Apply multiParse here as well
          images: multiParse(product.images || "[]"), // If images also might need parsing, apply it here
        });
      });
    }
  }, [id, serviceId]);
  
  // multiParse function definition
  function multiParse(input) {
    let result = input;
    try {
      while (typeof result === 'string') {
        result = JSON.parse(result);
      }
    } catch (e) {
      console.error("Error parsing input:", e);
    }
    return result;
  }
  

  const formik = useFormik({
    initialValues: {
      serviceId: serviceId || "",
      price: "",
      show_price:"",
      discounted_price:"",
      title: "",
      type:"",
      sub_type:"",
      top_featured:"",
      description: "",
      quantity:"",
      addOns: addOns,
      images: [],
      imageColors: [],
    },
    
    onSubmit: async (values) => {
      try {
        console.log("Form Values:", values);

        let formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("price", values.price);
        formData.append("show_price", values.show_price);
        console.log("Form Data (show_price):", values.show_price); // Log show_price value
        formData.append("discounted_price", values.discounted_price);
        formData.append("quantity", values.quantity);
        formData.append("type", values.type);
        formData.append("sub_type", values.sub_type);
        formData.append("top_featured", values.top_featured);
        formData.append("addOns", JSON.stringify(values.addOns));

        const imageColorsMap = await handleImagesUpload();
        Object.keys(imageColorsMap).forEach((name) => {
          formData.append("images", name);
          formData.append("imageColors", imageColorsMap[name]);
        });

        ProductsApi.updateProducts(id, formData).then(() => {
          navigate("/v1/products/detail");
        });
      } catch (error) {
        console.error("Error occurred during form submission:", error);
      }
    },
  });

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 10, // Maximum file size in MB
      maxWidthOrHeight: 800, // Maximum width or height of the image
      useWebWorker: true, // Enable web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);
    const compressedFiles = await Promise.all(
      files.map((file) => compressImage(file))
    );

    setImages((prevImages) => [...prevImages, ...compressedFiles]);
    setImagePreviews((prevPreviews) => [
      ...prevPreviews,
      ...compressedFiles.map((file) => URL.createObjectURL(file)),
    ]);
    setImageColors((prevColors) => [
      ...prevColors,
      ...Array(files.length).fill(""),
    ]);
  };

  const handleColorChange = (index, event) => {
    const updatedColors = [...imageColors];
    updatedColors[index] = event.target.value;
    setImageColors(updatedColors);
  };
  const handleImagesUpload = async () => {
    if (images.length > 0) {
      try {
        const uploadPromises = images.map((file) => {
          const formData = new FormData();
          formData.append("file", file);
          return UploadImageApi.uploadImage(formData);
        });

        const responses = await Promise.all(uploadPromises);
        console.log("Upload responses:", responses);

        const imageNames = responses.map((res) => res.data.data.name);
        const imageColorsMap = imageNames.reduce((acc, name, index) => {
          acc[name] = imageColors[index] || "";
          return acc;
        }, {});

        return imageColorsMap;
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    }
    return {};
  };

  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);

    const updatedColors = [...imageColors];
    updatedColors.splice(index, 1);
    setImageColors(updatedColors);
  };
 

  const handleAddOnChange = (index, field, value) => {
    const updatedAddOns = [...formik.values.addOns];
    updatedAddOns[index][field] = value;
    formik.setFieldValue("addOns", updatedAddOns);
  };

  const handleAddOnAdd = () => {
    formik.setFieldValue("addOns", [...formik.values.addOns, { key: "", price: "" }]);
  };

  const handleAddOnRemove = (index) => {
    const updatedAddOns = formik.values.addOns.filter((_, i) => i !== index);
    formik.setFieldValue("addOns", updatedAddOns);
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display={"flex"}>
            <ArrowBackIcon onClick={() => navigate("/v1/products/detail")} />
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              Edit Product Details
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "secondary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                "&:hover": {
                  background: "secondary.main",
                  outline: "none",
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>

        {/* Form fields */}
        <Box>
          <Grid container spacing={2}>
            {/* Price */}
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Price:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="price"
                  name="price"
                  type="number"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
              Show Price:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  id="show_price"
                  name="show_price"
                  value={formik.values.show_price}
                  onChange={(event) => {
                    formik.handleChange(event);
                    console.log(event.target.value); // Check what value is being passed here
                  }}                  displayEmpty
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
              Discounted Price:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="discounted_price"
                  name="discounted_price"
                  type="number"
                  value={formik.values.discounted_price}
                  onChange={formik.handleChange}
                  error={formik.touched.price && Boolean(formik.errors.discounted_price)}
                  helperText={formik.touched.discounted_price && formik.errors.discounted_price}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            {/* Title */}
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Title:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Description:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  multiline
                  rows={4}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
                    {/* Type */}
                    <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
              Image Color:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="imageColors"
                  name="imageColors"
                  value={formik.values.imageColors}
                  onChange={formik.handleChange}
                  error={formik.touched.imageColors && Boolean(formik.errors.imageColors)}
                  helperText={formik.touched.imageColors && formik.errors.imageColors}
                  variant="outlined"
                   InputProps={{
      readOnly: true,
    }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Type:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  id="type"
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  {typeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {formik.values.type === "Jewelry" && (
              <Grid item xs={12}>
                <InputLabel mt={2} className={classes.inputLabels}>
                  Sub Type:
                </InputLabel>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    fullWidth
                    id="sub_type"
                    name="sub_type"
                    value={formik.values.sub_type}
                    onChange={formik.handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Sub Type
                    </MenuItem>
                    {subTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Best Selling:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  fullWidth
                  id="top_featured"
                  name="top_featured"
                  value={formik.values.top_featured}
                  onChange={formik.handleChange}
                  displayEmpty
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
      {/* Quantity */}
      <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
              Quantity:
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="quantity"
                  name="quantity"
                  type="number"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.description && formik.errors.quantity}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            {/* Add-ons
            <Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Add-ons:
              </InputLabel>
              {formik.values.addOns.map((addOn, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        id={`addOns[${index}].key`}
                        name={`addOns[${index}].key`}
                        label="Key"
                        value={addOn.key}
                        onChange={(e) => handleAddOnChange(index, "key", e.target.value)}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl sx={{ width: "100%", marginBottom: "10px" }}>
                      <TextField
                        fullWidth
                        id={`addOns[${index}].price`}
                        name={`addOns[${index}].price`}
                        label="Price"
                        type="number"
                        value={addOn.price}
                        onChange={(e) => handleAddOnChange(index, "price", e.target.value)}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    {index !== 0 && (
                      <IconButton onClick={() => handleAddOnRemove(index)} sx={{ mt: 2 }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))}
              <Button
                onClick={handleAddOnAdd}
                variant="contained"
                sx={{
                  mt: 2,
                  fontWeight: 500,
                  fontSize: "14px",
                  background: "black",
                  borderRadius: "5px",
                  border: "none",
                  boxShadow: "none",
                  color: "#FFFFFF",
                  outline: "none",
                  "&:hover": {
                    background: "black",
                    outline: "none",
                  },
                }}
              >
                Add Add-On
              </Button>
            </Grid> */}

<Grid item xs={12}>
              <InputLabel mt={2} className={classes.inputLabels}>
                Images:
              </InputLabel>
              <input
                type="file"
                multiple
                onChange={handleImageChange}
              />
              <Box mt={2} display="flex" flexWrap="wrap">
                {imagePreviews.map((preview, index) => (
                  <Box key={index} sx={{ position: "relative", margin: 1 }}>
                    <img
                      src={preview}
                      alt={`Preview ${index}`}
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                    <IconButton
                      onClick={() => handleImageDelete(index)}
                      sx={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <ColorSelector
                      selectedColor={imageColors[index]}
                      onColorChange={(e) => handleColorChange(index, e)}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default EditProductDetails;
