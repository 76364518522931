import axios from "../axiosConfig";

class Routes {
  // get all services
  getBanner = () => {
    let data = axios.get("/banner/get");
    return data;
  };
  // /add-products/detail
  addBanner = (data) => {
    let response = axios.post("/banner/update", data);
    return response;
  };
    // /add-products/detail
    createBanner = (data) => {
      let response = axios.post("/banner/add", data);
      return response;
    };

 
}

export default new Routes();
