import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button, Modal, Backdrop, Fade, IconButton, TextField } from "@mui/material";
import { ProductsApi } from "../../../apis";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

function Services() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [discountCouponModalOpen, setDiscountCouponModalOpen] = useState(false);
  const [coupon_name, setCouponName] = useState(""); // For both adding and editing
  const [coupon_discount, setCouponDiscount] = useState(0); // For both adding and editing
  const [editMode, setEditMode] = useState(false); // To differentiate between add and edit
  const [selectedCouponId, setSelectedCouponId] = useState(null); // ID of the coupon being edited

  const fetchData = async () => {
    try {
      const response = await ProductsApi.getCoupon();
      setData(response.data.coupons);
    } catch (error) {
      setError('Failed to fetch coupons.');
      console.error("Error fetching coupons:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleDelete = (id) => {
    ProductsApi.deleteCoupon(id)
      .then((response) => {
        console.log(`Coupon with ID ${id} deleted successfully.`, response);
        // Remove the deleted coupon from the state immediately
        setData((prevData) => prevData.filter((coupon) => coupon.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting coupon:", error);
      });
  };
  

  const handleDiscountCouponModalClose = () => {
    setDiscountCouponModalOpen(false);
    setEditMode(false); // Reset edit mode
    setCouponName(""); // Reset coupon name
    setCouponDiscount(0); // Reset coupon discount
  };

  const handleDiscountCouponSubmit = () => {
    if (editMode) {
      handleUpdateCoupon();
    } else {
      handleAddCoupon();
    }
    handleDiscountCouponModalClose();
  };

  const handleAddCoupon = async () => {
    try {
      const res = await ProductsApi.addCoupon({
        coupon_code: coupon_name,
        discount: coupon_discount,
      });
      console.log("Coupon created successfully:", res.data);
      fetchData();
    } catch (error) {
      console.error("Error creating coupon:", error);
    }
  };

  // New function to handle coupon update
  const handleUpdateCoupon = async () => {
    try {
      const res = await ProductsApi.updateCoupon(selectedCouponId, {
        coupon_code: coupon_name,
        discount: coupon_discount,
      });
      console.log("Coupon updated successfully:", res.data);
      fetchData();
    } catch (error) {
      console.error("Error updating coupon:", error);
    }
  };

  const handleEdit = (id, coupon_code, discount) => {
    setSelectedCouponId(id); // Set the coupon to be edited
    setCouponName(coupon_code); // Pre-fill the coupon name
    setCouponDiscount(discount); // Pre-fill the discount
    setEditMode(true); // Switch to edit mode
    setDiscountCouponModalOpen(true); // Open the modal
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            View Coupons
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setCouponName(""); // Clear fields for adding new coupon
              setCouponDiscount(0);
              setEditMode(false); // Make sure we are in add mode
              setDiscountCouponModalOpen(true); // Open the modal for adding
            }}
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              background: "primary.main",
              borderRadius: "5px",
              border: "none",
              boxShadow: "none",
              color: "#FFFFFF",
              outline: "none",
              ml: 2,
              "&:hover": {
                background: "primary.main",
                outline: "none",
              },
            }}
          >
            Add Coupon
          </Button>
        </Box>
        {error && (
          <Typography color="error" align="center" sx={{ marginBottom: "20px" }}>
            {error}
          </Typography>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <table className="table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ paddingRight: "20px" }}>Coupon ID</th>
                  <th style={{ paddingLeft: "40px" }}>Coupon Code</th>
                  <th style={{ paddingLeft: "40px" }}>Discount</th>
                  <th style={{ paddingLeft: "40px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((coupon) => (
                    <tr key={coupon.id}>
                      <td style={{ paddingLeft: "40px" }}>{coupon.id}</td>
                      <td style={{ paddingLeft: "40px" }}>{coupon.coupon_code}</td>
                      <td style={{ paddingLeft: "40px" }}>{coupon.discount}</td>
                      <td>
                        <ButtonGroup variant="text" aria-label="large button group" sx={{ paddingLeft: 2 }}>
                          <Button  style={{ color: 'grey' }} onClick={() => handleEdit(coupon.id, coupon.coupon_code, coupon.discount)}>
                            <BorderColorIcon />
                          </Button>
                          <Button onClick={() => handleDelete(coupon.id)}>
                            <DeleteIcon color="error" />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>
                      No Coupons available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>

      {/* Modal for Add/Edit Coupon */}
      <Modal
        open={discountCouponModalOpen}
        onClose={handleDiscountCouponModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={discountCouponModalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <IconButton
              onClick={handleDiscountCouponModalClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "gray",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {editMode ? "Edit Coupon" : "Add Coupon"}
            </Typography>
            <TextField
              placeholder="Discount Coupon Name"
              fullWidth
              margin="normal"
              value={coupon_name}
              onChange={(e) => setCouponName(e.target.value)}
            />
            <TextField
              placeholder="Discount (%)"
              type="number"
              fullWidth
              margin="normal"
              value={coupon_discount}
              onChange={(e) => setCouponDiscount(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleDiscountCouponSubmit}
              fullWidth
            >
              {editMode ? "Update Coupon" : "Add Coupon"}
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Services;
