import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from "@mui/styles";
import BannerImage from '../../assets/images/sl4.png';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '200px',
    backgroundImage: `url(${BannerImage})`, // Replace with actual image path
    backgroundSize: 'cover',
    backgroundPosition: 'center', // Center the background image
    color: '#FFF', // White text
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    textAlign: 'left',
    padding: '20px',
    '@media (max-width: 920px)': {
      height: '150px', // Adjust height for smaller screens
      backgroundImage: 'none', // Remove background image on smaller screens
    }
  },
  headline1: {
    marginBottom: '16px',
    paddingLeft: '5%',
    '@media (max-width: 600px)': {
      paddingLeft: '2%', // Adjust padding for smaller screens
    }
  },
  headline2: {
    marginBottom: '20px',
    fontWeight: 'bold',
    paddingLeft: '5%',
    color: '#834F37',
    '@media (max-width: 600px)': {
      paddingLeft: '2%', // Adjust padding for smaller screens
    }
  },
  subtitle: {
    paddingLeft: '5.3%',
    fontWeight: "light",
    '@media (max-width: 600px)': {
      paddingLeft: '2%', // Adjust padding for smaller screens
    }
  },
  buttonArea: {
    paddingLeft: '5.1%',
    marginTop: "40px",
    '& > *': {
      margin: '8px',
    },
    '@media (max-width: 600px)': {
      paddingLeft: '2%', // Adjust padding for smaller screens
    }
  }
});

function CompanySection() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {/* Content goes here */}
    </Box>
  );
}

export default CompanySection;
