import React from 'react';
import { TextField, Button, Grid, Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ContactApi } from '../../apis';

// Validation schema using yup
const validationSchema = yup.object({
  name: yup.string().required('Name is required').min(2, 'Name must be at least 2 characters'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup
  .string()
  .matches(/^[0-9]+$/, 'Phone number must only contain numbers')
  .min(11, 'Phone number must be 11 digits') // Ensure the phone number has at least 11 digits
  .max(11, 'Phone number must not exceed 11 digits') // Ensure the phone number does not exceed 11 digits
  .required('Phone number is required'),
  message: yup.string().required('Message is required'),
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginTop: '190px',
    maxWidth: '500px',
    margin: 'auto',
    backgroundColor: '#ddac88',
    borderRadius: '12px',
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.9)',
    border: '2px solid #834F37',
  },
  form: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5),
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: '#007bff',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  headerTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    color: '#333',
  },
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
}));

const ContactUs = () => {
  const classes = useStyles();

  // Use Formik for form handling and validation
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting, setStatus }) => {
      setSubmitting(true);
      try {
        await ContactApi.addcontact(values); // Ensure this matches your API method
        setStatus({ success: 'Message sent successfully!' });
        resetForm();
      } catch (error) {
        setStatus({ error: 'Failed to send the message. Please try again.' });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Paper className={classes.root}>
      <Typography variant="h4" className={classes.headerTitle}>
        Contact Us
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              variant="outlined"
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant="outlined"
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              variant="outlined"
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              variant="outlined"
              multiline
              rows={4}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Grid>
          {formik.status?.success && <Typography color="green" align="center">{formik.status.success}</Typography>}
          {formik.status?.error && <Typography color="red" align="center">{formik.status.error}</Typography>}
        </Grid>
      </form>
    </Paper>
  );
};

export default ContactUs;
