import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Container, IconButton, InputAdornment } from '@mui/material';
import Logo from "../../assets/images/SALEECA.png";
import { useFormik } from 'formik';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthApi } from '../../apis'; 
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required'),
});

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res = await AuthApi.login(values);
        console.log("response",res.data.token);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", res.data.user);
        if (res.data.token) {
         
          window.location.href = "/v1/products/detail";
        }
      } catch (err) {
        console.error('Login Error:', err);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ backgroundColor: 'secondary.main', minHeight: '100vh', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', padding: 3, borderRadius: 2, boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)', width: '30%', height: "350px", maxWidth: '600px', marginTop: "50px", '@media (max-width: 1000px)': { width: '40%' }, '@media (max-width: 600px)': { width: '50%' } }}>
        <img src={Logo} alt="Logo" style={{ width: '50px', marginLeft: '20px' }} />
        <Typography component="h1" variant="h5" color="primary">Sign in</Typography>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
  <Typography
    component="h2"
    variant="body1"
    sx={{
      alignSelf: 'flex-start',
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'none',
      color: 'black',
      paddingTop: '10px',
    }}
  >
    Email Address
  </Typography>
  <TextField
    fullWidth
    margin="normal"
    id="email"
    name="email"
    placeholder="Enter email address"
    value={formik.values.email}
    onChange={formik.handleChange}
    error={formik.touched.email && Boolean(formik.errors.email)}
    helperText={formik.touched.email && formik.errors.email}
    sx={{
      width: '100%', // Increase the width of the TextField here
      margin: '5px 0',
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#F6D8C6',
        },
      },
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: '#F6D8C6',
        },
      },
    }}
  />

  <Typography
    component="h2"
    variant="body1"
    sx={{
      alignSelf: 'flex-start',
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'none',
      color: 'black',
      paddingTop: '10px',
    }}
  >
    Password
  </Typography>
  <TextField
    margin="normal"
    fullWidth
    name="password"
    placeholder="Enter password"
    type={showPassword ? 'text' : 'password'}
    id="password"
    value={formik.values.password}
    onChange={formik.handleChange}
    error={formik.touched.password && Boolean(formik.errors.password)}
    helperText={formik.touched.password && formik.errors.password}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            sx={{ p: '6px' }}
          >
            {showPassword ? <VisibilityOff sx={{ fontSize: '18px' }} /> : <Visibility sx={{ fontSize: '18px' }} />}
          </IconButton>
        </InputAdornment>
      ),
    }}
    sx={{
      width: '100%', // Increase the width of the TextField here
      margin: '5px 0',
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#F6D8C6',
        },
      },
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: '#F6D8C6',
        },
      },
    }}
  />
  <Button
    type="submit"
    fullWidth
    variant="contained"
    color="primary"
    sx={{ mt: 3, mb: 2 }}
    disabled={loading}

  >
    {loading ? <CircularProgress size={24} /> : 'Sign In'}
  </Button>
</Box>

      </Box>
    </Container>
  );
};

export default LoginPage;
