import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ProductsApi } from '../../apis';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  card: {
    border: '1px solid gray',
    textAlign: 'center',
    height: '370px', // Ensures square shape
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Space between image and text
    transition: 'transform 0.3s ease-in-out', // Animation on hover
    '&:hover': {
      transform: 'scale(1.05)', // Slightly increase size on hover
    },
  },
  image: {
    width: '100%',
    height: '70%',
    objectFit: 'cover',
  },
  stepTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: 'black',
  },
  stepDescription: {
    fontSize: '1rem',
    color: '#333',
    marginBottom: theme.spacing(1),
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  headerTitle: {
    fontWeight: 'normal',
    fontSize: '3rem !important',
  },
}));

const Steps = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  function multiParse(data, depth = 1) {
    let parsedData = data;
  
    for (let i = 0; i < depth; i++) {
      if (typeof parsedData === 'string') {
        try {
          parsedData = JSON.parse(parsedData);
        } catch (error) {
          console.error("Parsing error at depth", i + 1, ":", error.message);
          break;
        }
      } else {
        console.warn("Skipping parsing at depth", i + 1, ": data is not a string");
        break;
      }
    }
  
    return parsedData;
  }
  

// Fetch products from API
useEffect(() => {
    ProductsApi.getProducts()
        .then((res) => {
            const parsedData = res.data.products
                .map((product) => {
                    let images = multiParse(product.images || '[]'); // Use multiParse here
                    return { ...product, images };
                })
                .filter((product) => product.top_featured.toLowerCase() === 'active'); // Filter only active top-featured products
            
            setProducts(parsedData);
        })
        .catch((error) => console.error('Error fetching products:', error));
}, []);


  return (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h4" sx={{ fontSize: isSmallScreen ? '1rem' : '2.5rem' ,color:"#4b3539"}} className={classes.headerTitle}>
        Best Sellers
        </Typography>
      </Box>
      <Grid container spacing={3}>
  {products.map((product, index) => {
    // Construct the image URL
    const imageUrl = 
      Array.isArray(product.images) && product.images.length > 0
        ? `${process.env.REACT_APP_API_BASE_URL}/public/image/${product.images[0]}`
        : `${process.env.REACT_APP_API_BASE_URL}/public/image/${product.images}`; // Fallback image

    return (
      <Grid item xs={6} sm={6} md={3} key={index}> {/* 6 for two items per row on small screens */}
        <Card className={classes.card} onClick={() => navigate(`/product/${product.id}`)}>
          <img
            src={imageUrl}
            alt={product.title}
            className={classes.image}
            style={{ width: '100%', height: '270px', objectFit: 'cover' }}
          />
          <CardContent>
            <Typography className={classes.stepTitle}>{product.title}</Typography>
            <div>
              {product.discounted_price ? (
                <>
                  {product.show_price === "active" || product.show_price === "Active" && (
                    <Typography
                      variant="subtitle1"
                      color="black"
                      style={{
                        textDecoration: 'line-through',
                        marginRight: '8px',
                        color: 'grey',
                      }}
                    >
                      PKR {product.price}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle1"
                    color="#f57c00"
                    mt={product.show_price === "active" || product.show_price === "Active" ? -1 : 1}
                  >
                    PKR {product.discounted_price}
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle1" color="#f57c00">
                  PKR {product.price}
                </Typography>
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  })}
</Grid>

    </Container>
  );
};

export default Steps;
