import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, ButtonGroup, Button, Modal, Backdrop, Fade, IconButton,TextField } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import "./styles.css";
import { ProductsApi } from "../../../apis";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';


function ProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageColors, setSelectedImageColors] = useState([]);
  const [open, setOpen] = useState(false);
  const [discountCouponModalOpen, setDiscountCouponModalOpen] = useState(false);
  const [coupon_name, setCouponName] = useState(""); // Change initial value to 0
  const [coupon_discount, setCouponDiscount] = useState(0); // Change initial value to 0
  const [discountModalOpen, setDiscountModalOpen] = useState(false);
  const [discount, setDiscount] = useState(0); // Change initial value to 0
  function multiParse(data, depth = 1) {
    let parsedData = data;
  
    for (let i = 0; i < depth; i++) {
      if (typeof parsedData === 'string') {
        try {
          parsedData = JSON.parse(parsedData);
        } catch (error) {
          console.error("Parsing error at depth", i + 1, ":", error.message);
          break;
        }
      } else {
        console.warn("Skipping parsing at depth", i + 1, ": data is not a string");
        break;
      }
    }
  
    return parsedData;
  }
  
  const fetchAndParseProducts = async () => {
    try {
      const res = await ProductsApi.getProducts();
      const parsedData = res.data.products.map(service => {
        let images = [];
        let addOns = [];

        // Parse images
        if (service.images) {
          images = multiParse(service.images);  // Adjust the depth as necessary
          if (!Array.isArray(images)) {
            images = [images];
          }
        }

        // Parse addOns
        if (service.addOns) {
          addOns = multiParse(service.addOns, 2);  // Adjust the depth as necessary
          if (!Array.isArray(addOns)) {
            addOns = [addOns];
          }
        }

        return { ...service, images, addOns };
      });

      setData(parsedData); // Set parsed data to state
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchAndParseProducts();
  }, []);

  const handleImageClick = (images, imageColors,id) => {
    // Ensure images and imageColors are arrays, even if there's only one item
    const imageArray = Array.isArray(images) ? images : [images];
    const colorArray = Array.isArray(imageColors) ? imageColors : [imageColors];
  
    setSelectedImages(imageArray);
    setSelectedImageColors(colorArray);
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDiscountModalClose = () => {
    setDiscountModalOpen(false);
  };

  const handleDiscountSubmit = () => {
    applyDiscount();
    handleDiscountModalClose();
  };

  const handleDiscountCouponModalClose = () => {
    setDiscountCouponModalOpen(false);
  };

  const handleDiscountCouponSubmit = () => {
    handleAddCoupon();
    handleDiscountCouponModalClose();
  };
  const handleDeleteIconClick = (index) => {
    const updatedImages = [...selectedImages];
    const updatedColors = [...selectedImageColors];
    updatedImages.splice(index, 1);
    updatedColors.splice(index, 1);
    setSelectedImages(updatedImages);
    setSelectedImageColors(updatedColors);
  };

  const handleSave = (id) => {
    if (!id) {
      console.error("No ID provided for saving.");
      return;
    }
    
    // Make API call to update product images
    ProductsApi.updateProducts(id, selectedImages)
      .then((res) => {
        console.log("Product updated successfully:", res);
        
        // Update the local data array to reflect the updated images
        setData((prevData) => {
          return prevData.map((product) => {
            if (product.id === id) {
              return {
                ...product,
                images: selectedImages,  // Update images in the product list
              };
            }
            return product;
          });
        });
        
        handleClose();  // Close the modal after save
      })
      .catch((error) => {
        console.error("Error updating images:", error);
      });
  };
  const handleAddCoupon = async () => {
    try {
      const res = await ProductsApi.addCoupon({
        coupon_code:coupon_name,
        discount:coupon_discount,
      });
      console.log("Coupon created successfully:", res.data);
      // Optionally reset fields or show a success message
      setCouponName("");
      setCouponDiscount(0);
    } catch (error) {
      console.error("Error creating coupon:", error);
    }
  };
  // Apply discount to all products
  const applyDiscount = () => {
    const updatedProducts = data.map((product) => ({
      ...product,
      discounted_price: product.price - (product.price * discount) / 100, // Apply discount to original price
    }));

    // Update each product's price through API
    updatedProducts.forEach((product) => {
      ProductsApi.updateProducts(product.id, { discounted_price: product.discounted_price })
        .then((res) => {
          console.log(`Product ${product.id} price updated successfully.`);
        })
        .catch((error) => {
          console.error(`Error updating price for product ${product.id}:`, error);
        });
    });

    setData(updatedProducts); // Update the state to reflect the new prices
  };



  const handleDelete = (id) => {
    ProductsApi.deleteProducts(id)
      .then(() => {
        console.log(`Service with ID ${id} deleted successfully.`);
        fetchAndParseProducts();
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
      });
  };

  return (
    <Box
      sx={{
        background: "#FFFFFF",
        borderRadius: "14px",
        padding: "30px",
      }}
    >
      <div className="table-responsive" style={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
        <Box display={"flex"}>
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px" }}>
              View Product Details
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={() => navigate(`/v1/add-products-detail`)}
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "secondary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                "&:hover": {
                  background: "secondary.main",
                  outline: "none",
                },
              }}
            >
              Add Product Details
            </Button>
            <Button
              variant="contained"
              onClick={() => setDiscountModalOpen(true)} // Open discount modal
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "primary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                ml: 2,
                "&:hover": {
                  background: "primary.main",
                  outline: "none",
                },
              }}
            >
              Update Discount
            </Button>
            {/* <Button
              variant="contained"
              onClick={() => setDiscountCouponModalOpen(true)} // Open discount modal
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                background: "primary.main",
                borderRadius: "5px",
                border: "none",
                boxShadow: "none",
                color: "#FFFFFF",
                outline: "none",
                ml: 2,
                "&:hover": {
                  background: "primary.main",
                  outline: "none",
                },
              }}
            >
              Add Coupon
            </Button> */}
          </Box>
        </Box>
        <Grid container spacing={2} pt={5}>
          <Grid xs={12}>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ paddingRight: "20px" }}>Product ID</th>
                  <th style={{ paddingLeft: "40px" }}>Title</th>
                  <th style={{ paddingLeft: "40px" }}>Description</th>
                  <th style={{ paddingLeft: "40px" }}>Price</th>
                  <th style={{ paddingLeft: "40px" }}>Discounted Price</th>
                  <th style={{ paddingLeft: "40px" }}>Quantity</th>
                  <th style={{ paddingLeft: "40px" }}>Type</th>
                  <th style={{ paddingLeft: "40px" }}>Sub Type</th>
                  <th style={{ paddingLeft: "40px" }}>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((service, index) => (
                    <tr key={index}>
                      <td style={{ paddingLeft: "40px" }}>{service.id}</td>
                      <td style={{ paddingLeft: "40px" }}>{service.title}</td>
                      <td style={{ paddingLeft: "40px" }}>{service.description}</td>
                      <td style={{ paddingLeft: "40px" }}>PKR {service.price} </td>
                      <td style={{ paddingLeft: "40px" }}>PKR {service.discounted_price}</td>
                      <td style={{ paddingLeft: "40px" }}>{service.quantity}</td>
                      <td style={{ paddingLeft: "40px" }}>{service.type}</td>
                      <td style={{ paddingLeft: "40px" }}>{service.sub_type}</td>
                      <td style={{ paddingLeft: "40px" }}>
                        {service.images && service.images.length > 0 ? (
                          <Button
                            sx={{
                              mt: 2,
                              fontWeight: 500,
                              fontSize: "14px",
                              background: "black",
                              borderRadius: "5px",
                              border: "none",
                              boxShadow: "none",
                              color: "#FFFFFF",
                              outline: "none",
                              "&:hover": {
                                background: "black",
                                outline: "none",
                              },
                            }}
                            onClick={() => handleImageClick(service.images, service.imageColors,service.id)}
                          >
                            View Images
                          </Button>
                        ) : (
                          <Typography variant="body1" sx={{ color: 'black' }}>
                            No image available
                          </Typography>
                        )}
                      </td>
                      <td>
                        <ButtonGroup variant="text" aria-label="large button group" sx={{ paddingLeft: "auto" }}>
                          <Button
                            style={{ color: 'grey' }}
                            onClick={() => navigate(`/v1/edit-products-detail/${service.id}`, { state: { serviceId: service.id } })}
                          >
                            <BorderColorIcon />
                          </Button>
                          <Button onClick={() => handleDelete(service.id)}>
                            <DeleteIcon color="error" />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" style={{ textAlign: "center" }}>No products available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fff',
              boxShadow: 24,
              borderRadius: '10px',
              p: 8,
            }}
          >
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {Array.isArray(selectedImages) && selectedImages.length > 0 ? (
                selectedImages.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'relative',
                      width: "80px",
                      height: "80px",
                      marginRight: "20px",
                    }}
                  >
                    {/* <IconButton
                      sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                      onClick={() => handleDeleteIconClick(index)}
                    >
                      <DeleteIcon />
                    </IconButton> */}
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${image}`}
                      alt={image}
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  </Box>
                ))
              ) : (
                <Typography variant="body1">No images available</Typography>
              )}
            </Box>
            {/* <Button
  variant="contained"
  color="primary"
  onClick={() => handleSave(selectedId)}  // Use arrow function to defer execution
  sx={{ mt: 2 }}
>
  Save
</Button> */}

          </Box>
        </Fade>
      </Modal>
      <Modal
          open={discountModalOpen}
          onClose={handleDiscountModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={discountModalOpen}>
            <Box sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}>
              <IconButton
                onClick={handleDiscountModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'gray',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Update Discount</Typography>
              <TextField
                placeholder="Discount (%)"
                type="number"
                fullWidth
                margin="normal"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleDiscountSubmit}
                fullWidth
              >
                Apply Discount
              </Button>
            </Box>
          </Fade>
        </Modal>
        <Modal
          open={discountCouponModalOpen}
          onClose={handleDiscountCouponModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={discountCouponModalOpen}>
            <Box sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}>
              <IconButton
                onClick={handleDiscountCouponModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'gray',
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Coupon</Typography>
              <TextField
                placeholder="Discount Coupon Name"
                fullWidth
                margin="normal"
                value={coupon_name}
                onChange={(e) => setCouponName(e.target.value)}
              />
              <TextField
                placeholder="Discount (%)"
                type="number"
                fullWidth
                margin="normal"
                value={coupon_discount}
                onChange={(e) => setCouponDiscount(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleDiscountCouponSubmit}
                fullWidth
              >
                Add Coupon
              </Button>
            </Box>
          </Fade>
        </Modal>
    </Box>
  );
}

export default ProductDetails;
