import React from "react";
import AppRoutes from "../../router/AdminRoutes";

import AdminDashboard from "./AdminDashboard";
 function Main() {
    return (
      <AdminDashboard>
        <AppRoutes />
      </AdminDashboard>
    );
  }
  
  // Export the Main component
  export default Main;