import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import DreamJob2 from "../../assets/images/sl15.png"; // Second image
import DreamJob3 from "../../assets/images/purse.png"; // Third image
import { useNavigate } from "react-router-dom";

function DreamJobSection() {
    const theme = useTheme();
    const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const images = [DreamJob2, DreamJob3]; // Array of images
    const [currentImage, setCurrentImage] = useState(0);

    // Cycle through images every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval); // Clean up on unmount
    }, [images.length]);

    return (
        <Box sx={{
            
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "#AB7B5D",
            padding: "50px 0px 50px 0px",
            height: matchesSmall ? "600px" : "400px" // Adjust height based on screen size
        }}>
            <Grid container>
                <Grid item xs={12} sm={6} md={6}>
                    <Box
                        sx={{
                            width: "300px",
                            height: "350px",
                            border: "1px dotted transparent",
                            borderRadius: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "15px",
                            margin: "auto",
                            overflow: "hidden", // Ensure the image doesn't overflow the box
                        }}
                    >
                        <motion.img 
                            key={currentImage} // Use key to trigger re-render on image change
                            src={images[currentImage]} 
                            alt="Dream Job"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "30px",
                            }}
                            initial={{ opacity: 0 }} // Start with hidden
                            animate={{ opacity: 1 }} // Fade in
                            transition={{ duration: 1 }} // Animation duration
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} sx={{
                    backgroundColor: '#AB7B5D',
                    paddingLeft: "3%",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: "350px",
                    px:5,
                }}>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with text slightly below and invisible
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and correct position
                        transition={{ duration: 1, delay: 0.2 }} // Delay to create a staggered effect
                    >
                        <Typography variant="h4" component="div" sx={{ color: 'white', fontWeight: 'bold', mb: 2 }}>
                            Discover Your Perfect Bag
                        </Typography>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with text slightly below and invisible
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and correct position
                        transition={{ duration: 1, delay: 0.4 }} // Delay to create a staggered effect
                    >
                        <Box sx={{ display: 'flex', mb: 2 }}>
                            <Typography variant="body1" sx={{ color: '#4b3539', fontSize: "16px" }}>
                                <Typography variant="body1" sx={{ color: '#4b3539', fontWeight: 'bold' }}>
                                    Upgrade your look effortlessly with Saleeca bags
                                </Typography> that effortlessly blend style and sophistication.
                            </Typography>
                        </Box>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with button slightly below and invisible
                        animate={{ opacity: 1, y: 0 }} // Animate to visible and correct position
                        transition={{ duration: 1, delay: 0.6 }} // Delay to create a staggered effect
                    >
                        <Button variant="outlined" color="primary"  onClick={() => navigate("/products")} sx={{
                            width: "200px", color: '#4b3539', borderColor: '#4b3539', mt: 3, fontWeight: 'bold', '&:hover': {
                                borderColor: '#4b3539'
                            }
                            
                        }}>
                            <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: '#4b3539', padding: "5px" }}>
                                Shop
                            </Typography>
                        </Button>
                    </motion.div>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DreamJobSection;
