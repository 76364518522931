import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { BannerApi, UploadImageApi } from "../../../apis";
import { makeStyles } from "@mui/styles";
import imageCompression from 'browser-image-compression'; // Import the compression library

const useStyles = makeStyles({
  inputLabels: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#31394D",
    textAlign: "left",
  },
});

const AddProductDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  // Extract ID from location state
  const { id } = location.state || {}; // Default to an empty object if state is undefined

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      id: id || '', // Use default empty string if id is undefined
      image: "", // Single image name
    },
    onSubmit: async (values) => {
      try {
        console.log("Form Values:", values);
    
        let formData = new FormData();
        const { id } = values; // Destructure the id for easier usage
    
        // Add the form data fields
        formData.append("id", values.id || ""); // Append ID if available, otherwise append empty string
        const imageName = await handleImageUpload(); // Upload the image and get its name
        if (imageName) {
          formData.append("image", imageName); // Append single image name
        }
    
        // Check if `id` exists; if yes, update the banner; if no, create a new banner
        if (id) {
          // If an ID is available, update the banner using addBanner API
          await BannerApi.addBanner(formData);
        } else {
          // If no ID is available, create a new banner using createBanner API
          await BannerApi.createBanner(formData);
        }
    
        // Navigate to the banner listing page after success
        navigate("/v1/banner");
      } catch (error) {
        console.error("Error occurred during form submission:", error);
      }
    
    },
  });
  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 10, // Maximum file size in MB
      maxWidthOrHeight: 800, // Maximum width or height of the image
      useWebWorker: true, // Enable web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };
  const handleImageChange = (event) => {
    const file = compressImage(event.target.files[0]); // Handle only the first file
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file)); // Update preview with single image
    }
  };

  const handleImageUpload = async () => {
    if (!image) return ""; // No image to upload

    const formData = new FormData();
    formData.append("file", image);

    try {
      const response = await UploadImageApi.uploadImage(formData);
      console.log("Uploaded Image Name:", response.data.data.name);
      return response.data.data.name; // Assuming API returns the image name
    } catch (error) {
      console.error("Error uploading image:", error);
      return ""; // Return empty string on error
    }
  };

  useEffect(() => {
    // If ID is undefined, navigate back or handle it accordingly
    if (!id) {
      navigate("/v1/banner"); // Or any other route
    }
  }, [id, navigate]);

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">
          Update Banner
        </Typography>
      </Box>

      <form
        onSubmit={async (event) => {
          event.preventDefault();
          const imageName = await handleImageUpload(); // Upload the image and get its name
          if (imageName) {
            formik.setFieldValue("image", imageName); // Update Formik with the image name
            formik.handleSubmit(); // Submit the form
          }
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.inputLabels}>
              Upload Image
            </Typography>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            {imagePreview && (
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <img
                    src={imagePreview} // Display the single image preview
                    alt="preview"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddProductDetails;
