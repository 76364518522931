// src/App.js
import React from 'react';
import ViewBanner from '../../pages/Admin/Banner/viewBanner';
import ViewContacts from '../../pages/Admin/Contacts/viewContacts';
import ViewReviews from '../../pages/Admin/Reviews/viewReviews';
import ViewCoupon from '../../pages/Admin/Coupon/viewCoupon';
import ViewShipping from '../../pages/Admin/Shipping/viewShipping';
import ViewCheckout from '../../pages/Admin/Checkout/viewCheckout';
import AddBanner from '../../pages/Admin/Banner/addBannnerPic';
import ViewProductDetail from '../../pages/Admin/ProductDetail/viewProductDetail';
import AddProductDetails from '../../pages/Admin/ProductDetail/addProductDetail';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import EditProductDetails from '../../pages/Admin/ProductDetail/editProductDetail';


function AppRoutes() {
  return (
        <Routes>
            
          {/* <Route path="/" element={<products />} /> */}
          <Route path="/banner" element={<ViewBanner />} />
          <Route path="/add-banner" element={<AddBanner />} />
          <Route path="/edit-banner/:id" element={<AddBanner />} />
          <Route path="/products/detail" element={<ViewProductDetail/>} />
          <Route path="/add-products-detail" element={<AddProductDetails/>} />
          <Route path="/edit-products-detail/:id" element={<EditProductDetails/>} />
          <Route path="/contacts" element={<ViewContacts />} />
          <Route path="/reviews" element={<ViewReviews />} />
          <Route path="/coupon" element={<ViewCoupon />} />
          <Route path="/shipping" element={<ViewShipping />} />
          <Route path="/checkout" element={<ViewCheckout />} />
        </Routes>
  );
}

export default AppRoutes;
