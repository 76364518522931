import React from 'react';
import { Grid, Box, Container } from '@mui/material';
import Checkout from '../../components/checkout/index';
import NavBar from '../../components/Products/navBar';
import Footer from '../../components/LandingPage/Footer';
import CompanyImage1 from "../../assets/images/bannerr.png";
import CompanyImage2 from "../../assets/images/sl2.png";

const CheckoutPage = () => {
  // Example product object, should be fetched or passed via context
 
  

  return (
    <>
      <NavBar />
      <Box sx={{ backgroundColor: 'white', minHeight: '100vh', mt: 0 }}>
        <Container>
         
            <Grid item xs={12} >
              <Checkout  /> {/* Pass product data if needed */}
            </Grid>
        
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default CheckoutPage;
